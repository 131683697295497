import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { AliasDomain, ContentDomainResponse } from 'interfaces/contentMapping.model'
import { ApiService } from '../../services/apiService'

interface SetupContentMappingSliceInterface {
  domains: ContentDomainResponse[]
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: SetupContentMappingSliceInterface = {
  domains: new Array<ContentDomainResponse>(),
  inProcess: false,
  errors: null,
}

const setupContentMappingSlice = createSlice({
  name: 'contentMapping',
  initialState,
  reducers: {
    setDomains(state, action: PayloadAction<ContentDomainResponse[]>) {
      state.domains = action.payload
    },
    addDomain(state, action: PayloadAction<ContentDomainResponse>) {
      state.domains.push(action.payload)
    },
    deleteDomain(state, action: PayloadAction<ContentDomainResponse>) {
      state.domains.push(action.payload)
    },
    setDomainError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setDomainProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
  },
})

export const { setDomains, addDomain, deleteDomain, setDomainError, setDomainProgress } =
  setupContentMappingSlice.actions

export const getDomains = createAsyncThunk(
  'contentMapping/getDomains',
  async (arg: { publisherId: number }, { dispatch }) => {
    dispatch(setDomainProgress(true))
    dispatch(setDomains([]))

    try {
      const response = await ApiService.get<ContentDomainResponse[]>(
        `/v1/publisher/${arg.publisherId}/domains`
      )

      const { success, message, data } = response

      dispatch(setDomainProgress(false))

      if (!success) {
        dispatch(setDomainError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setDomains(data))
      }
    } catch (ex: any) {
      dispatch(setDomainProgress(false))
      dispatch(setDomainError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const saveDomain = createAsyncThunk(
  'contentMapping/saveDomain',
  async (arg: { publisherId: number; domain: AliasDomain }, { dispatch }) => {
    dispatch(setDomainProgress(true))
    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${arg.publisherId}/domains/addDomain`,
        arg.domain
      )
      const { success, message } = response
      dispatch(setDomainProgress(false))

      if (!success) {
        dispatch(setDomainError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(
          addDomain({
            domain: arg.domain.domain,
            isPrimary: false,
          })
        )
      }

      return success
    } catch (ex: any) {
      dispatch(setDomainProgress(true))
      dispatch(setDomainError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return false
    }
  }
)

export const deleteDomainData = createAsyncThunk(
  'contentMapping/deleteDomain',
  async (arg: { publisherId: number; domain: AliasDomain }, { dispatch }) => {
    dispatch(setDomainProgress(true))
    try {
      const response = await ApiService.post<AliasDomain>(
        `/v1/publisher/${arg.publisherId}/domains/deleteDomain`,
        arg.domain
      )
      const { success, message } = response
      dispatch(setDomainProgress(false))

      if (!success) {
        dispatch(setDomainError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(
          deleteDomain({
            domain: arg.domain.domain,
            isPrimary: false,
          })
        )
      }
      return success
    } catch (ex: any) {
      dispatch(setDomainProgress(true))
      dispatch(setDomainError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export default setupContentMappingSlice
