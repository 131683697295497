import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { globalData } from 'interfaces/globalData.model'

const initialState: globalData = {
  enableCampaign: false,
}

const globalSlice = createSlice({
  name: 'globalData',
  initialState,
  reducers: {
    editEnableCampaign(state, action: PayloadAction<boolean>) {
      state.enableCampaign = action.payload
    },
  },
})
export const { editEnableCampaign } = globalSlice.actions

export default globalSlice
