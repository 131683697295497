import { configureStore } from '@reduxjs/toolkit'
import linkoutSlice from 'containers/Linkout/linkout-slice'
import acceptBidSlice from './slice/acceptBid-slice'
import activitiesSlice from './slice/activities-slice'
import adminSlice from './slice/admin-slice'
import analyticsSlice from './slice/analytics-slice'
import applicationSlice from './slice/application-slice'
import authSlice from './slice/auth-slice'
import balanceSlice from './slice/balance-slice'
import campaignSlice from './slice/campaign-slice'
import categoryGeographySlice from './slice/categoryGeography-slice'
import customerRefundSlice from './slice/customerRefund-slice'
import geographySlice from './slice/geography-slice'
import globalSlice from './slice/global-slice'
import publisherSlice from './slice/publisher-slice'
import setupContentMappingSlice from './slice/setupContentMapping-slice'
import setupUserInterface from './slice/setupUserInterface-slice'
import trafficSlice from './slice/traffic-slice'
import userSlice from './slice/user-slice'
import filterGeographySlice from './slice/filter-geography-slice'
import getRsourceSlice from './slice/resourceCenter-slice'
import businessRewardsSlice from 'containers/BusinessRewards/businessRewards-slice'
import sponsorBusinessManageSlice from 'components/LeftNav/ManageSponsors/sponsorBusinessManage-slice'
import publisherOrdinalitySlice from 'components/PublisherOrdinality/publisherOrdinality-slice'
import paymentMethodsSlice from 'components/ManagePaymentMethods/ManagePaymentMethod-slice'

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    auth: authSlice.reducer,
    publisher: publisherSlice.reducer,
    activities: activitiesSlice.reducer,
    traffic: trafficSlice.reducer,
    analytics: analyticsSlice.reducer,
    geography: geographySlice.reducer,
    categoryGeography: categoryGeographySlice.reducer,
    contentMapping: setupContentMappingSlice.reducer,
    userInterface: setupUserInterface.reducer,
    application: applicationSlice.reducer,
    admin: adminSlice.reducer,
    campaign: campaignSlice.reducer,
    globalData: globalSlice.reducer,
    balance: balanceSlice.reducer,
    linkout: linkoutSlice.reducer,
    businessRewards: businessRewardsSlice.reducer,
    sponsorBusinessManage: sponsorBusinessManageSlice.reducer,
    publisherOrdinality: publisherOrdinalitySlice.reducer,
    paymentMethods: paymentMethodsSlice.reducer,
    acceptBid: acceptBidSlice.reducer,
    customerRefund: customerRefundSlice.reducer,
    filterGeography: filterGeographySlice.reducer,
    resourceSlice: getRsourceSlice.reducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export default store
