import {
  Grid,
} from '@mui/material'

import { FC } from 'react'
import Menu from "./Menu"

const ResourceMenu: FC = () => {
  return (
    <Grid item container direction={'column'} mt={2}>
        <Grid item sx={{width: "100%"}}>
          <Menu />
        </Grid>
    </Grid>
  )
}

export default ResourceMenu
