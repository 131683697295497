import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { NewPublisher } from 'interfaces/newPublisher.model'
import {
  Publisher,
  PublisherCurrenciesResponse,
  PublisherResponse,
} from 'interfaces/publisher.model'
import { PublisherDetail } from 'interfaces/publisherDetail.model'
import { ArticleBulkPriceRequest } from 'interfaces/request/ArticleBulkPriceRequest.model'
import { ArticleMetaDataRequest } from 'interfaces/request/ArticleMetaDataRequest.model'
import { ArticlePriceRequest } from 'interfaces/request/ArticlePriceRequest.model'
import { CategoryReq } from 'interfaces/request/categoryReq.model'
import { ArticleResponse, AllArticleResponse } from 'interfaces/response/articleResponse.model'
import { CategoryResponse } from 'interfaces/response/categoryResponse.model'
import { PublisherGroupRequest } from 'interfaces/response/publisherGroupResponse.model'
import toast from 'react-hot-toast'
import { CategoryPriceRequest } from './../../interfaces/request/CategoryPriceRequest.model'
import { PublisherCategoryResponse } from './../../interfaces/response/publisherCategoryResponse.model'
import { ApiService } from './../../services/apiService'
import { RejectValue, SuccessMessage } from './slice-types'

interface PublisherEmail {
  email: string
}

interface PublisherId {
  publisherId: number
}


export const getAllPublisher = createAsyncThunk<Publisher[], PublisherEmail, RejectValue>(
  'publisher/getAllPublisher',
  async (args: PublisherEmail, { rejectWithValue }) => {
    try {
      const response = await ApiService.get<PublisherResponse>(`/v1/publisher/getAll/${args.email}`)
      const { success, message, data } = response
      if (!success) {
        return rejectWithValue({ error: message || COMMON_FAILURE })
      } else {
        return data.publishers
      }
    } catch (ex: any) {
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

export const getPublisherDetail = createAsyncThunk<PublisherDetail, PublisherId, RejectValue>(
  'publisher/getPublisherDetail',
  async (args: PublisherId, { rejectWithValue }) => {
    const toasterId = 'getPublisherDetailToaster'
    try {
      const response = await ApiService.get<PublisherDetail>(`/v1/publisher/${args.publisherId}`)
      const { success, message, data } = response
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ error: message || COMMON_FAILURE })
      } else {
        return data
      }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

export const getPublisherCategories = createAsyncThunk(
  'publisher/getPublisherCategories',
  async (arg: CategoryReq, { dispatch }) => {
    dispatch(setPublisherCategoryInProcess(true))
    dispatch(
      setPublisherCategories({
        categories: [],
        categoryCount: 0,
      })
    )
    try {
      const response = await ApiService.post<PublisherCategoryResponse>(
        `/v1/publisher/${arg.publisherId}/content/categories/${arg.page}/${arg.size}`,
        arg.requestObject
      )
      const { success, message, data } = response
      dispatch(setPublisherCategoryInProcess(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setPublisherCategories(data))
      }
    } catch (ex: any) {
      dispatch(setPublisherCategoryInProcess(false))
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const getPublisherCurrency = createAsyncThunk(
  'publisher/getPublisherCurrency',
  async (arg: PublisherId, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    try {
      const response = await ApiService.get<PublisherCurrenciesResponse>(`/v1/currency/all`)
      const { success, message, data } = response
      dispatch(setPublisherProgress(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setPublisherCurrency(data))
      }
    } catch (ex: any) {
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const getAllCurrencies = createAsyncThunk('getAllCurrencies', async () => {
  const toasterId = 'getAllCurrencies'
  try {
    const response = await ApiService.get<any>(`/v1/currency/all`)
    const { success, message, data } = response
    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    } else {
      return data
    }
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
})

export const getLanguages = createAsyncThunk('getLanguages', async () => {
  const toasterId = 'getLanguages'
  try {
    const response = await ApiService.get<any>(`/v1/customer/getLanguages`)
    const { success, message, data } = response
    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    } else {
      return data
    }
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
})

interface CatArticle {
  publisherId: number
  page: number
  size: number
  requestObject: PublisherGroupRequest
}
export const getCategoryArticles = createAsyncThunk<void, CatArticle, {}>(
  'publisher/getCategoryArticles',
  async (arg: CatArticle, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    dispatch(setCategoryArticles({ items: [], totalCount: 0 }))
    try {
      const response = await ApiService.post<ArticleResponse>(
        `/v1/publisher/${arg.publisherId}/content/${arg.page}/${arg.size}`,
        arg.requestObject
      )
      const { success, message, data } = response
      dispatch(setPublisherProgress(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setCategoryArticles(data))
       
      }
    } catch (ex: any) {
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const getCategoryArticlesAll = createAsyncThunk<void, CatArticle, {}>(
  'publisher/getCategoryArticlesAll',
  async (arg: CatArticle, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    dispatch(setAllCategoryArticles({ items: [], totalAllCount: 0 }))
    try {
      const response = await ApiService.post<AllArticleResponse>(
        `/v1/publisher/${arg.publisherId}/content/${arg.page}/${arg.size}`,
        arg.requestObject
      )
      const { success, message, data } = response
      dispatch(setPublisherProgress(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setAllCategoryArticles(data))
      }
    } catch (ex: any) {
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

interface PropsMonetize {
  publisherId: number
  categoryId: number
  monetizeType: string
}
export const updateCategoryMonetizeState = createAsyncThunk<SuccessMessage, PropsMonetize, {}>(
  'publisher/updateCategoryMonetizeState',
  async (args: PropsMonetize, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    const { categoryId, monetizeType, publisherId } = args

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/category/${categoryId}/switchMonetizeType/${monetizeType}`,
        {}
      )

      const { success, message } = response

      dispatch(setPublisherProgress(false))

      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setPublisherCategoryMonetizeType({ id: categoryId, monetizeType }))
      }

      return { success, message }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': errorMessage }))
      return { success: false, message: errorMessage }
    }
  }
)

interface PropsCategoryAutoEnable {
  publisherId: number
  categoryId: number
  isAutoEnable: boolean
}

export const updateCategoryAutoEnableState = createAsyncThunk<SuccessMessage, PropsCategoryAutoEnable, {}>(
  'publisher/updateCategoryAutoEnableState',
  async (args: PropsCategoryAutoEnable, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    const { categoryId, isAutoEnable, publisherId } = args

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/articleCategory/${categoryId}/autoEnableContent/${isAutoEnable}`,
        {}
      )
      const { success, message } = response
      dispatch(setPublisherProgress(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setPublisherCategoryAutoEnableState({ id: categoryId, isAutoEnable }))
      }
      return { success, message }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': errorMessage }))

      return { success: false, message: errorMessage }
    }
  }
)

interface PropsCategoryActive {
  publisherId: number
  categoryId: number
  isActive: boolean
}

export const updateCategoryActiveState = createAsyncThunk<SuccessMessage, PropsCategoryActive, {}>(
  'publisher/updateCategoryActiveState',
  async (args: PropsCategoryActive, { dispatch }) => {
    dispatch(setPublisherProgress(true))
    const { categoryId, isActive, publisherId } = args

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/articleCategory/${categoryId}/active/${isActive}`,
        {}
      )
      const { success, message } = response
      dispatch(setPublisherProgress(false))
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setPublisherCategoryActiveState({ id: categoryId, isActive }))
      }
      return { success, message }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': errorMessage }))

      return { success: false, message: errorMessage }
    }
  }
)


interface MonetizeState {
  ids: number[]
  monetizeType: string
}
export const updateArticleMonetizeState = createAsyncThunk<boolean, MonetizeState, {}>(
  'publisher/updateArticleMonetizeState',
  async (arg: MonetizeState, { dispatch }) => {
    dispatch(setPublisherProgress(true))

    try {
      const response = await ApiService.put<any>(
        `/v1/article/switchMonetizeType/${arg.monetizeType}`,
        arg.ids
      )

      const { success, message } = response

      dispatch(setPublisherProgress(false))

      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setArticleMonetizeType({ ids: arg.ids, monetizeType: arg.monetizeType }))
      }

      return success
    } catch (ex: any) {
      dispatch(setPublisherProgress(true))
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return false
    }
  }
)

interface ArticleActive {
  ids: number[]
  active: boolean
}

export const updateArticleActive = createAsyncThunk<boolean, ArticleActive, {}>(
  'publisher/updateArticleActive',
  async (arg: ArticleActive, { dispatch }) => {
    try {
      let apiUrl = '/v1/article/activateAll'
      if (!arg.active) {
        apiUrl = '/v1/article/deActivateAll'
      }
      const response = await ApiService.put<any>(apiUrl, arg.ids)
      const { success, message } = response

      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setArticleActive({ ids: arg.ids, active: arg.active }))
      }
      return success
    } catch (ex: any) {
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return false
    }
  }
)

interface UpdateCatPrice {
  publisherId: number
  categoryId: number
  categoryPrice: CategoryPriceRequest
}

export const updateCategoryPrice = createAsyncThunk<boolean, UpdateCatPrice, {}>(
  'publisher/updateCategoryPrice',
  async (args: UpdateCatPrice, { dispatch }) => {
    const { publisherId, categoryId, categoryPrice } = args
    const toastId = toast.loading('Updating category price...')

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/articleCategory/${categoryId}/updatePriceSetting`,
        categoryPrice
      )
      const { success, message } = response
      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
        toast.error(message || COMMON_FAILURE, { id: toastId })
        return success
      } else {
        dispatch(setCategoryPrice({ categoryId, categoryPrice }))
        toast.success('Updated successfully.', { id: toastId })
        return success
      }
    } catch (ex: any) {
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      toast.error(ex?.message || COMMON_FAILURE, { id: toastId })
      return false
    }
  }
)

export const updateArticlePrice = createAsyncThunk<any, ArticlePriceRequest, {}>(
  'publisher/updateArticlePrice',
  async (articleSingleRequest: ArticlePriceRequest, { dispatch }) => {
    const toastId = toast.loading('Updating article price...')

    try {
      const response = await ApiService.put<any>(`/v1/article/update`, articleSingleRequest)

      const { success, message, data } = response

      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
        toast.error(message || COMMON_FAILURE, { id: toastId })
        return null
      } else {
        dispatch(setSingleArticlePrice({ articleSingleRequest }))
        toast.success('Updated successfully.', { id: toastId })
        return data
      }
    } catch (ex: any) {
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      toast.error(ex.message || COMMON_FAILURE, { id: toastId })
      return null
    }
  }
)

interface ArticlePriceReq {
  monetizeType: string
  articleBulkPriceUpdateRequest: ArticleBulkPriceRequest
}
export const updateBulkArticlePrice = createAsyncThunk<any, ArticlePriceReq, {}>(
  'publisher/updateBulkArticlePrice',
  async (args: ArticlePriceReq, { dispatch }) => {
    const { monetizeType, articleBulkPriceUpdateRequest } = args
    const toastId = toast.loading('Updating articles price...')

    try {
      let requestUrl = '/v1/article/bulkUpdate/updatePaywallPrices'

      if (monetizeType === 'tipjar') {
        requestUrl = '/v1/article/bulkUpdate/updateTipjarPrices'
      }

      const response = await ApiService.post<any>(requestUrl, articleBulkPriceUpdateRequest)

      const { success, message, data } = response

      if (!success) {
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
        toast.error(message || COMMON_FAILURE, { id: toastId })
        return null
      } else {
        dispatch(setBulkArticlePrice({ monetizeType, articleBulkPriceUpdateRequest }))
        toast.success('Updated successfully.', { id: toastId })
        return data
      }
    } catch (ex: any) {
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      toast.error(ex.message || COMMON_FAILURE, { id: toastId })
      return null
    }
  }
)

export const updateArticleMetaData = createAsyncThunk<any, ArticleMetaDataRequest, {}>(
  'publisher/updateArticleMetaData',
  async (articleMetaDataRequest: ArticleMetaDataRequest, { dispatch }) => {
    const toastId = toast.loading('Updating article meta data...')
    try {
      const response = await ApiService.post<any>(
        `/v1/article/bulkUpdate/updateMetaData`,
        articleMetaDataRequest
      )
      const { success, message, data } = response
      if (!success) {
        toast.error(message || COMMON_FAILURE, { id: toastId })
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
        return null
      } else {
        toast.success('Updated successfully.', { id: toastId })
        return data
      }
    } catch (ex: any) {
      toast.error(ex?.message || COMMON_FAILURE, { id: toastId })
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return null
    }
  }
)

export const addNewPublisher = createAsyncThunk<any, NewPublisher, {}>(
  'publisher/addNewPublisher',
  async (newPublisher: NewPublisher, { dispatch }) => {
    const toastId = toast.loading('Adding...')
    try {
      const response = await ApiService.post<any>(`/v1/publisher/create`, newPublisher)
      const { success, message, data } = response
      if (!success) {
        toast.error(message || COMMON_FAILURE, { id: toastId })
        dispatch(setPublisherError({ error: message || COMMON_FAILURE }))
        return null
      } else {
        toast.success('Publisher has been added.', { id: toastId })
        return data
      }
    } catch (ex: any) {
      toast.error(ex?.message || COMMON_FAILURE, { id: toastId })
      dispatch(setPublisherError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return null
    }
  }
)

interface PublisherSliceInterface {
  publisherCurrenciesResponse: PublisherCurrenciesResponse
  publishers: Publisher[]
  publishersLoaded: boolean
  isPublishersInProcess: boolean
  inProcess: boolean
  selectedPublisher: Publisher | null
  publisherDetail: PublisherDetail | null
  errorsPublisherDetail: { [k: string]: string } | null
  inProcessPublisherDetail: boolean
  publisherCategoryInProcess: boolean
  publisherCategories: CategoryResponse
  categoryArticles: ArticleResponse
  allCategoryArticles: AllArticleResponse
  errors: { [k: string]: string } | null
}


const initialState: PublisherSliceInterface = {
  publishers: [],
  publishersLoaded: false,
  isPublishersInProcess: false,
  inProcess: false,
  selectedPublisher: null,
  publisherDetail: null,
  inProcessPublisherDetail: false,
  errorsPublisherDetail: null,
  publisherCategoryInProcess: false,
  publisherCategories: {
    categoryCount: 0,
    categories: [],
  },
  publisherCurrenciesResponse: {
    currencies: [],
  },
  categoryArticles: { items: [], totalCount: 0, statsUpdateMetaData: undefined },
  allCategoryArticles: { items: [], totalAllCount: 0, statsUpdateMetaData: undefined },
  errors: null,
}

const publisherSlice = createSlice({
  name: 'publisher',
  initialState,
  reducers: {
    setAllPublisher(state, action: PayloadAction<Publisher[]>) {
      state.publishers = action.payload
    },
    setPublisherIsOpenState(state, action: PayloadAction<Publisher>) {
      const publisher = action.payload

      state.publishers.forEach((x) => {
        if (x.id === publisher.id) {
          x.isSelected = !x.isSelected
        } else {
          x.isSelected = false
        }
      })
    },
    setPublisherSelected(state, action: PayloadAction<string | null>) {
      if (action.payload === null) {
        state.selectedPublisher = null
      } else {
        const selectedPublisher = state.publishers.find((p) => p.slug === action.payload)

        state.selectedPublisher = selectedPublisher ?? null
      }
    },
    setSelectedPublisherPrimaryMonetizeType(state, action) {
      const selectedPublisher = state.publishers.find((p) => p.id === action.payload.id)

      selectedPublisher!.primaryMonetizeType! = action.payload.primaryMonetizeType
    },
    setSelectedPublisherFeatureData(state, action) {
      const selectedPublisher = state.publishers.find((p) => p.id === action.payload.id)
      selectedPublisher!.feature! = action.payload
    },
    setPublisherCategoryInProcess(state, action: PayloadAction<boolean>) {
      state.publisherCategoryInProcess = action.payload
    },

    setPublisherCategories(state, action: PayloadAction<CategoryResponse>) {
      state.publisherCategories = action.payload
    },
    setPublisherCurrency(state, action: PayloadAction<PublisherCurrenciesResponse>) {
      state.publisherCurrenciesResponse = action.payload
    },
    setCategoryArticles(state, action: PayloadAction<ArticleResponse>) {
      state.categoryArticles = action.payload
    },
     setAllCategoryArticles(state, action: PayloadAction<AllArticleResponse>) {
      state.allCategoryArticles = action.payload
    },
    removeCategoryArticles(state, action: PayloadAction<number[]>) {
      const selectedIds = action.payload
      state.categoryArticles.items = state.categoryArticles.items.filter(
        (x) => !selectedIds.includes(x.id)
      )
      state.categoryArticles.totalCount = state.categoryArticles.items.length
      state.allCategoryArticles.totalAllCount = state.allCategoryArticles.items.length
    },
    setArticleMonetizeType(state, action: PayloadAction<{ ids: number[]; monetizeType: string }>) {
      const { ids, monetizeType } = action.payload

      const articles = state.categoryArticles.items.filter((x) => ids.includes(x.id))
      if (articles.length > 0) {
        articles.map((x) => (x.monetizeType = monetizeType))
      }
    },
    setArticleActive(state, action: PayloadAction<{ ids: number[]; active: boolean }>) {
      const { ids, active } = action.payload

      const articles = state.categoryArticles.items.filter((x) => ids.includes(x.id))
      if (articles.length > 0) {
        articles.map((x) => (x.active = active))
      }
    },
    setPublisherCategoryMonetizeType(
      state,
      action: PayloadAction<{ id: number; monetizeType: string }>
    ) {},
    setPublisherCategoryActiveState(
      state,
      action: PayloadAction<{ id: number; isActive: boolean }>
    ) {
      const { id, isActive } = action.payload

      const category = state.publisherCategories.categories.find((x) => x.id === id)
      if (category) {
        category.isActive = isActive
      }
    },
    setPublisherCategoryAutoEnableState(
      state,
      action: PayloadAction<{ id: number; isAutoEnable: boolean }>
    ) {
      const { id, isAutoEnable} = action.payload

      const category = state.publisherCategories.categories.find((x) => x.id === id)
      if (category) {
        category.isAutoEnabled = isAutoEnable
      }
    },
    removeCategory(state, action: PayloadAction<number>) {
      state.publisherCategories.categories = state.publisherCategories.categories.filter(
        (x) => x.id !== action.payload
      )
    },
    setPublisherError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setPublisherProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
    setArticleSelected(
      state,
      action: PayloadAction<{ selected: boolean; payload: Number | string }>
    ) {
      if (typeof action.payload.payload === 'string' && action.payload.payload === 'all') {
        state.categoryArticles.items.map((x) => (x.selected = action.payload.selected))
      } else {
        const article = state.categoryArticles.items.find((x) => x.id === action.payload.payload)
        if (article) {
          article.selected = action.payload.selected
        }
      }
    },
    setPublisherGeoFencingStatus(
      state,
      action: PayloadAction<{ publisherId: number; enabled: boolean }>
    ) {
      const { publisherId, enabled } = action.payload
      const publisher = state.publishers.find((x) => x.id === publisherId)
      if (publisher) {
        publisher.feature.geoFencingEnabled = enabled
      }

      if (state.selectedPublisher) {
        state.selectedPublisher.feature.geoFencingEnabled = enabled
      }
    },
    setPublisherGeoPricingStatus(
      state,
      action: PayloadAction<{ publisherId: number; enabled: boolean }>
    ) {
      const { publisherId, enabled } = action.payload
      const publisher = state.publishers.find((x) => x.id === publisherId)
      if (publisher) {
        publisher.feature.geoPricingEnabled = enabled
      }

      if (state.selectedPublisher) {
        state.selectedPublisher.feature.geoPricingEnabled = enabled
      }
    },

    setCategoryPrice(
      state,
      action: PayloadAction<{
        categoryId: number
        categoryPrice: CategoryPriceRequest
      }>
    ) {
      const { categoryId, categoryPrice } = action.payload

      const categoryArticle = state.publisherCategories.categories.find((x) => x.id === categoryId)

      if (categoryArticle) {
        categoryArticle.price = categoryPrice.paywallPrice
        categoryArticle.tipjarPriceOptions.prices = categoryPrice.tipJarPrice
      }
    },

    // article
    setSingleArticlePrice(
      state,
      action: PayloadAction<{ articleSingleRequest: ArticlePriceRequest }>
    ) {
      const { articleSingleRequest } = action.payload

      const article = state.categoryArticles.items.find((x) => x.id === articleSingleRequest.id)

      if (article) {
        article.price = articleSingleRequest.price
        article.tipJarPriceOptions.prices = articleSingleRequest.tipJarPrice
      }
    },
    setBulkArticlePrice(
      state,
      action: PayloadAction<{
        monetizeType: string
        articleBulkPriceUpdateRequest: ArticleBulkPriceRequest
      }>
    ) {
      const {
        monetizeType,
        articleBulkPriceUpdateRequest: { ids, prices },
      } = action.payload

      const articles = state.categoryArticles.items.filter((x) => ids.includes(x.id))

      if (articles && articles.length) {
        if (monetizeType === 'paywall') {
          articles.map((x) => (x.price = prices[0]))
        } else {
          articles.map((x) => (x.tipJarPriceOptions.prices = prices))
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPublisher.pending, (state, { payload }) => {
      state.isPublishersInProcess = true
      state.publishers = []
    })
    builder.addCase(getAllPublisher.fulfilled, (state, { payload }) => {
      state.publishers = payload.sort((a, b) => a.domain.trim().localeCompare(b.domain.trim()))
      state.isPublishersInProcess = false
      state.publishersLoaded = true
    })
    builder.addCase(getAllPublisher.rejected, (state, { payload }) => {
      state.isPublishersInProcess = false

      state.publishers = []
      state.errors = payload ?? null
    })

    builder.addCase(getPublisherDetail.pending, (state, { payload }) => {
      state.selectedPublisher = null
      state.inProcessPublisherDetail = true
    })
    builder.addCase(getPublisherDetail.fulfilled, (state, { payload }) => {
      state.inProcessPublisherDetail = false
      state.selectedPublisher = payload
    })
    builder.addCase(getPublisherDetail.rejected, (state, { payload }) => {
      state.inProcessPublisherDetail = false
      state.selectedPublisher = null
      state.errors = payload ?? null
    })
  },
})

export const {
  setAllPublisher,
  setPublisherIsOpenState,
  setPublisherSelected,
  setPublisherCategories,
  setCategoryArticles,
  setAllCategoryArticles,
  removeCategory,
  removeCategoryArticles,
  setArticleMonetizeType,
  setArticleActive,
  setPublisherCategoryMonetizeType,
  setPublisherCategoryActiveState,
  setPublisherCategoryAutoEnableState,
  setPublisherError,
  setPublisherProgress,
  setArticleSelected,
  setPublisherGeoFencingStatus,
  setPublisherGeoPricingStatus,
  setCategoryPrice,
  setSingleArticlePrice,
  setBulkArticlePrice,
  setPublisherCategoryInProcess,
  setPublisherCurrency,
  setSelectedPublisherFeatureData,
  setSelectedPublisherPrimaryMonetizeType,
} = publisherSlice.actions

export default publisherSlice
