import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Currency } from 'interfaces/currency.model'
import { ApiService } from 'services/apiService'

type ApplicationSliceType = {
  countries: Currency[]
  inProcess: boolean
  leftSidebarOpen: boolean
  leftSideBarFullMode: boolean
  errors: { [k: string]: string } | null
}

export const getCountries = createAsyncThunk<Currency[], void, {}>(
  'application/getCountries',
  async () => {
    try {
      const response = await ApiService.get<{ currencies: Currency[] }>(`/v1/country/getCountries`)

      const { data } = response
      return data.currencies
    } catch (ex: any) {
      return []
    }
  }
)

const initialState: ApplicationSliceType = {
  countries: [],
  inProcess: false,
  leftSidebarOpen: false,
  leftSideBarFullMode: true,
  errors: null,
}

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setCountries(state, action: PayloadAction<Currency[]>) {
      state.countries = action.payload
    },
    toggleLeftSidebarOpen(state) {
      state.leftSidebarOpen = !state.leftSidebarOpen
    },
    toggleLeftSideBarFullMode(state) {
      state.leftSideBarFullMode = !state.leftSideBarFullMode
    },
    setLeftSidebarOpen(state, action: PayloadAction<boolean>) {
      state.leftSidebarOpen = action.payload
    },
    setLeftSideBarFullMode(state, action: PayloadAction<boolean>) {
      state.leftSideBarFullMode = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCountries.fulfilled, (state, action) => {
      state.countries = action.payload
    })
  },
})

export const {
  setCountries,
  toggleLeftSidebarOpen,
  toggleLeftSideBarFullMode,
  setLeftSidebarOpen,
  setLeftSideBarFullMode,
} = applicationSlice.actions

export default applicationSlice
