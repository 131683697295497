import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { PublisherCampaigns, PublisherLedger } from 'interfaces/publisherLedger.model'
import { PublisherWallet } from 'interfaces/publisherWallet.model'
import toast from 'react-hot-toast'
import { ApiService } from 'services/apiService'
interface BalanceSliceInterface {
  wallets: PublisherWallet[]
  walletsInProcess: boolean
  ledger: PublisherLedger[]
  publisherCampaignsWithOrdinality: PublisherCampaigns[]
  ledgerInProcess: boolean
  errors: { [k: string]: string } | null
}

interface publisherIdArg {
  publisherId: number | null
}

export const getWallets = createAsyncThunk<
  PublisherWallet[],
  {
    publisherId: number
  },
  {
    rejectValue: { [k: string]: string }
  }
>('digitalAsset/getWallets', async (args: { publisherId: number }, { rejectWithValue }) => {
  const toasterId = 'getWallets'

  try {
    const { publisherId } = args
    const response = await ApiService.get<{ publisherWallets: PublisherWallet[] }>(
      `/v1/publisher/${publisherId}/wallet`
    )
    const { data, success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return data?.publisherWallets ?? []
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const getLedger = createAsyncThunk<
  PublisherLedger[],
  {
    publisherId: number
    timeDuration: any
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'digitalAsset/getLedger',
  async (args: { publisherId: number; timeDuration: any }, { rejectWithValue }) => {
    const toasterId = 'getLedger'

    try {
      const { publisherId, timeDuration } = args
      const response = await ApiService.get<{ publisherledgers: PublisherLedger[] }>(
        `/v1/publisher/${publisherId}/ledger?formatDate=false&duration=${timeDuration}`
      )
      const { data, success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      return data.publisherledgers.map((x, index) => {
        return { ...x, index: index + 1 }
      })
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

const initialState: BalanceSliceInterface = {
  wallets: [],
  walletsInProcess: false,
  ledger: [],
  publisherCampaignsWithOrdinality: [],
  ledgerInProcess: false,
  errors: null,
}

const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getWallets
    builder
      .addCase(getWallets.pending, (state) => {
        state.wallets = []
        state.walletsInProcess = true
      })
      .addCase(getWallets.fulfilled, (state, action) => {
        state.walletsInProcess = false
        state.wallets = action.payload
      })
      .addCase(getWallets.rejected, (state, action) => {
        state.wallets = []
        state.walletsInProcess = false
        state.errors = action.payload ?? null
      })

    // getLedger
    builder
      .addCase(getLedger.pending, (state) => {
        state.ledger = []
        state.ledgerInProcess = true
      })
      .addCase(getLedger.fulfilled, (state, action) => {
        state.ledgerInProcess = false
        state.ledger = action.payload
      })
      .addCase(getLedger.rejected, (state, action) => {
        state.ledger = []
        state.ledgerInProcess = false
        state.errors = action.payload ?? null
      })
  },
})

export default balanceSlice
