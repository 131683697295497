import config from 'config'
import { getAuthToken, getDeviceIdentity, getRefreshToken, setAuthToken } from 'util/auth'
import decodeToken from 'util/decodeToken'
import { handleApiErrors, manualLogout } from 'util/handleApiErrors'

let tokenTimeout: any

const getNextToken = async () => {
  const accessToken = getAuthToken()
  const refreshToken = getRefreshToken()
  const deviceKey = getDeviceIdentity()
  let decoded: any = null
  if (accessToken) {
    decoded = decodeToken(accessToken)
  }
  const email = decoded.username

  const payload = {
    deviceKey,
    email,
    refreshToken,
  }

  try {
    const ac = new AbortController()
    const signal = ac.signal
    const response = await fetch(`${config.ApiBaseUrl}/v1/user/nextAccessToken`, {
      signal,
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
    const verifiedResponse = handleApiErrors(response)
    const json = await verifiedResponse.json()
    const { data, message, success } = json

    if (!success) throw new Error(message ?? 'Something went wrong. failed to process request')

    setAuthToken(data.accessToken)
    decoded = decodeToken(data.accessToken)
    const expires = new Date(decoded.exp * 1000).getTime()
    const timeout = expires - Date.now() - 5 * 60 * 1000

    tokenTimeout = setTimeout(getNextToken, timeout)
  } catch (e) {
    manualLogout()
  }
}

const clearOutTokenTimeout = () => clearTimeout(tokenTimeout)

export { getNextToken, clearOutTokenTimeout }
