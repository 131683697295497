import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { ThemeProvider } from '@mui/material/styles'
import Loading from 'components/Loading'

import { PrivateRoute } from 'components/util'
import RootPage from 'containers/RootPage'
import { Role } from 'interfaces/role.enum'
import ModalProvider from 'mui-modal-provider'
import PublisherRoute from 'PublisherRoutes'
import { FC, lazy, Suspense } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import theme from 'Theme'

const Account = lazy(() => import('containers/Account'))
const Dashboard = lazy(() => import('containers/Dashboard'))
const Login = lazy(() => import('containers/Login'))
const AcceptBid = lazy(() => import('./components/LeftNav/AcceptBid'))
const CustomerRefund = lazy(() => import('./components/LeftNav/CustomerRefund'))
const PageNotFound = lazy(() => import('./components/PageNotFound'))
const GettingStarted = lazy(() => import('containers/GettingStarted'))
const ResourceCenter = lazy(() => import('containers/ResourceCenter'))
const ActivatePublisher = lazy(() => import('./components/LeftNav/ActivatePublisher'))
const BusinessRewards = lazy(() => import('containers/BusinessRewards'))
const LanguagePicker = lazy(() => import('./components/LanguagePicker'))
const ManagePaymentMethod = lazy(() => import('./components/ManagePaymentMethods'))
const SponsorManage = lazy(() => import('./components/LeftNav/ManageSponsors'))
export const muiCache = createCache({
  key: 'fc-publisher-portal-style-cache',
  //@ts-ignore
  prepend: true,
})

const App: FC = () => {
  return (
    //@ts-ignore
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ModalProvider>
            <Suspense fallback={<Loading />}>
              <Toaster toastOptions={{ style: { textAlign: 'center' } }} />
              <LanguagePicker />
              <Router>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <PrivateRoute exact={true} path="/gettingStarted">
                    <GettingStarted />
                  </PrivateRoute>
                  <PrivateRoute path="/dashboard">
                    <Dashboard />
                  </PrivateRoute>
                  <PrivateRoute path="/account">
                    <Account />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/acceptBid" roles={[Role.SuperAdmin]}>
                    <AcceptBid />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/sponsorManage" roles={[Role.SuperAdmin]}>
                    <SponsorManage />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/managePaymentMethod" roles={[Role.SuperAdmin]}>
                    <ManagePaymentMethod />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/customerRefund" roles={[Role.SuperAdmin]}>
                    <CustomerRefund />
                  </PrivateRoute>
                  <PrivateRoute path="/resourceCenter">
                    <ResourceCenter />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/activatePublisher" roles={[Role.SuperAdmin]}>
                    <ActivatePublisher />
                  </PrivateRoute>
                  <PrivateRoute exact={true} path="/businessRewards" roles={[Role.SuperAdmin]}>
                    <BusinessRewards />
                  </PrivateRoute>
                  <PrivateRoute path={'/:slug'}>
                    <PublisherRoute />
                  </PrivateRoute>

                  <Route exact path="/">
                    <RootPage />
                  </Route>
                  <Route path="*" component={PageNotFound} />
                </Switch>
              </Router>
            </Suspense>
          </ModalProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  )
}

export default App
