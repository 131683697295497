import { createAsyncThunk } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { AnswerType, Business, LeadForm, Question } from './businessRewards-types'

export const businessRewardSections = {
  MainSection: 'mainSection',
  SubSection: 'subSection',
}

interface publisherIdArg {
  publisherId: number | null
}

interface SaveRewardArgs {
  inputData: any
}

interface UploadCampaignBannerArgs {
  inputData: any
}
interface EditRewardArgs {
  inputData: any
  campaignId: any
}
interface PublisherRewardArgs {
  inputData: any
  campaignId: number
  publisherId: number | null
}

interface EditPublisherRewardArgs {
  campaignId: number
  publisherId: number | null
}

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

export const getBusinessRewardsCampaigns = createAsyncThunk<any>(
  'businessRewardsCampaigns/get',
  async () => {
    const toasterId = 'getRewardsToaster'
    try {
      const url = `/v1/reward/campaign`
      const response = await ApiService.get<any>(url)
      const { data, success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const getBusinessRewards = createAsyncThunk<Business | undefined>(
  'businessRewards/get',
  async () => {
    const toasterId = 'getRewardsToaster'
    try {
      const url = `/v1/reward/business`
      const response = await ApiService.get<Business>(url)
      const { data, success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const saveRewardCampaignDetails = createAsyncThunk<any, SaveRewardArgs, RejectValue>(
  'reward/saveCampaign',
  async (args: SaveRewardArgs, { rejectWithValue }) => {
    const { inputData } = args
    const { campaignId, ...postData } = inputData
    const toasterId = toast.loading('Saving campaign details...')
    try {
      const response = campaignId
        ? await ApiService.put<any>(`/v1/reward/campaign/${campaignId}`, postData)
        : await ApiService.post<any>(`/v1/reward/campaign`, postData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const uploadCampaignBanner = createAsyncThunk<any, UploadCampaignBannerArgs, RejectValue>(
  'reward/uploadCampaignBanner',
  async (args: UploadCampaignBannerArgs, {}) => {
    const { inputData } = args
    const toasterId = toast.loading('Uploading Image...')
    try {
      const url = `/v1/reward/campaign/uploadBanner`
      const payload = new FormData()
      payload.append('campaignName', inputData.campaignName)
      payload.append('businessName', inputData.businessName)
      payload.append('file', inputData.file)
      payload.append('bannerType', inputData.bannerType)
      const response = await ApiService.post<any>(url, payload, {
        headers: {},
      })
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      } else {
        toast.success(message, { id: toasterId })
        return data
      }
      return null
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const fetchPublishersBasedOnSearch = createAsyncThunk<any, EditRewardArgs, RejectValue>(
  'reward/publishers',
  async (args: EditRewardArgs, { rejectWithValue }) => {
    const { campaignId, inputData } = args
    const toasterId = 'fetchPublishersBasedOnSearchToaster'
    try {
      const response = await ApiService.post<any>(
        `/v1/reward/campaign/${campaignId}/publishers`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getPublisherCountries = createAsyncThunk<any>('publisherCountries/get', async () => {
  const toasterId = 'getPublisherCountriesToaster'
  try {
    const url = `/v1/publisher/admin/getPublisherCountries`
    const response = await ApiService.get<any>(url)
    const { data, success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
    return data
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
})

export const getFewcentsCategories = createAsyncThunk<any>('fewcentsCategories/get', async () => {
  const toasterId = 'getFewcentsCategoriesToaster'
  try {
    const url = `/v1/publisher/fewCentsCategories`
    const response = await ApiService.get<any>(url)
    const { data, success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
    return data
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
})

export const AddNewPublisherRewardCampaignDetails = createAsyncThunk<
  any,
  PublisherRewardArgs,
  RejectValue
>(
  'reward/AddNewPublisherRewardCampaignDetails',
  async (args: PublisherRewardArgs, { rejectWithValue }) => {
    const { inputData, campaignId, publisherId } = args
    const toasterId = toast.loading('Adding New Publisher Reward Campaign Details...')

    try {
      const response = await ApiService.post<any>(
        `/v1/reward/campaign/${campaignId}/publisher/${publisherId}`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const EditPublisherRewardCampaignDetails = createAsyncThunk<
  any,
  PublisherRewardArgs,
  RejectValue
>(
  'reward/EditPublisherRewardCampaignDetails',
  async (args: PublisherRewardArgs, { rejectWithValue }) => {
    const { inputData, campaignId, publisherId } = args
    const toasterId = toast.loading('Editing Publisher Reward Campaign Details...')

    try {
      const response = await ApiService.put<any>(
        `/v1/reward/campaign/${campaignId}/publisher/${publisherId}`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getPublisherCampaignDetails = createAsyncThunk<any, EditPublisherRewardArgs>(
  'edit/EditPublisherCampaignDetails',
  async (args: EditPublisherRewardArgs) => {
    const { campaignId, publisherId } = args
    const toasterId = toast.loading('Editing Publisher  Campaign Details...')

    try {
      const response = await ApiService.get<any>(
        `/v1/reward/campaign/${campaignId}/publisher/${publisherId}`
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const getPublisherCategories = createAsyncThunk<any, publisherIdArg>(
  'publisherCategories/get',
  async (args: publisherIdArg) => {
    const { publisherId } = args
    const toasterId = 'getPublisherCategoriesToaster'
    try {
      const url = `/v1/publisher/${publisherId}/articleCategories`
      const response = await ApiService.get<any>(url)
      const { data, success, message } = response
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

interface leadFormGetArgs {
  campaignId: number
}

export const getLeadForm = createAsyncThunk<LeadForm | null, leadFormGetArgs>(
  'publisherCategories/getLeadForm',
  async (args: leadFormGetArgs) => {
    const { campaignId } = args
    try {
      const response = await ApiService.get<LeadForm>(`/v1/reward/campaign/${campaignId}/leadForm`)
      const { data } = response
      data.questions.forEach((question: any) => {
        if (question.options?.items) question.options = question.options.items
      })

      return data
    } catch (ex: any) {
      return null
    }
  }
)
interface leadFormPostArgs extends leadFormGetArgs {
  leadForm: LeadForm
}

export const saveLeadForm = createAsyncThunk<LeadForm | null, leadFormPostArgs>(
  'campaignLeadForm/saveLeadForm',
  async (args: leadFormPostArgs) => {
    const { campaignId, leadForm } = args
    const { id, ...postdata } = leadForm
    try {
      const response = id
        ? await ApiService.put<LeadForm>(`/v1/reward/campaign/${campaignId}/leadForm`, postdata)
        : await ApiService.post<LeadForm>(`/v1/reward/campaign/${campaignId}/leadForm`, postdata)
      const { data } = response
      return data
    } catch (ex: any) {
      return null
    }
  }
)

export const getEmptyReward = () => ({
  id: null,
  campaignName: '',
  currency: '',
  country: '',
  amount: 100,
  budget: 100,
  active: true,
  shortDescriptionLoggedOut: '',
  shortDescriptionLoggedIn: '',
  splashUnlockedText: '',
  rewardBalanceText: '',
  termsConditionsHtml: '',
  pixel: '',
  advertiserService: '',
  tncExternalLink: '',
  longDescription: '',
  cardLogoUrl: '',
  paywallBannerUrl: '',
  splashBannerUrl: '',
  checkoutBannerUrl: '',
  leadFormBannerUrl: '',
  leadFormContentId: null,
  usageExpiryDays: 10,
  usageExpiryDate: null,
  business: null,
  hasLeadForm: false,
  leadForm: null,
})

export const getEmptyQuestion = (): Question => ({
  questionLabel: '',
  answerType: AnswerType.text,
  answerLength: 200,
  isHidden: false,
  isRequired: true,
  isActive: true,
  options: [],
})

export const getEmptyLeadForm = (): LeadForm => ({
  description: '',
  redirectUrl: '',
  completedUrl: '',
  failedUrl: '',
  notificationUrl: '',
  questions: [getEmptyQuestion()],
  internal: true,
})
