import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface filterGeographySliceModel {
  countryName?: string
  regionName?: string
}

const initialState: filterGeographySliceModel = {
  countryName: '',
  regionName: '',
}

const filterGeographySlice = createSlice({
  name: 'filterGeography',
  initialState,
  reducers: {
    setFilterGeographyCountry(state, action: PayloadAction<any>) {
      state.countryName = action.payload
    },
    setFilterGeographyRegion(state, action: PayloadAction<any>) {
      state.regionName = action.payload
    },
  },
})

export const { setFilterGeographyCountry, setFilterGeographyRegion } = filterGeographySlice.actions

export default filterGeographySlice
