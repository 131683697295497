export interface BusinessReward {
  id: number | null | undefined
  campaignName: string
  budget: number | null
  country: string | null
  currency: string | null
  amount: number | null
  active: boolean
  shortDescriptionLoggedOut: string
  shortDescriptionLoggedIn: string
  splashUnlockedText: string | null
  rewardBalanceText: string | null
  termsConditionsHtml: string | null
  pixel: string | null
  advertiserService: string | null
  tncExternalLink: string | null
  longDescription: string
  cardLogoUrl: string
  paywallBannerUrl: string
  splashBannerUrl: string
  checkoutBannerUrl: string
  leadFormBannerUrl: string
  leadFormContentId: number | null
  usageExpiryDays: number | null
  usageExpiryDate: Date | null
  business: Business | null
  hasLeadForm: boolean
  leadForm: LeadForm | null
}

export type BusinessRewardType = {
  list: BusinessReward[]
  publishersDetails: PublisherDetails[]
  publisherCountriesList: any
  fewcentsCategoriesList: any
  getBusinessRewardsCampaignsInProcess: boolean
  AddNewRewardCampaignDetailsInProcess: boolean
  getPublisherCountriesInProcess: boolean
  getFewcentsCategoriesInProcess: boolean
  errors: { [k: string]: string } | null
  selectedCampaign: BusinessReward
  publisherDetailsForSelectedCampaign: boolean
  publisherIdForSelectedTheRewardCampaign: number | null
}

export interface RejectMessage {
  [k: string]: string
}
export interface RejectValue {
  rejectValue: RejectMessage
}

export interface CampaignDetailsInterface {
  campaignDetails: any | null
  campaignDetailsInProcess: boolean
  errors: { [k: string]: string } | null
}
export const initialState: CampaignDetailsInterface = {
  campaignDetails: null,
  errors: null,
  campaignDetailsInProcess: false,
}

export interface PublisherDetails {
  publisherCountries: any
  publisherCategories: any
  useFilter: boolean
}

export interface PublisherDetailsOfSelectedPUblisher {
  publisherCountries: any
  publisherCategories: any
  publisherCurrencies: any
  startDate: any
  endDate: any
  ordinality: number | null
  shortDescriptionLoggedOut: string | null
  shortDescriptionLoggedIn: string | null
  emailDomains: string | null
  commission: string | null
  isActive: boolean
}

export interface Business {
  id: number
  logoUrl: string | null
  name: string
  notificationUrl: string | null
  signedRequest: boolean
  termsConditionsHtml: string | null
}

export enum AnswerType {
  checkbox = 'checkbox',
  single_select_dropdown = 'single_select_dropdown',
  text = 'text',
  date = 'date',
  multi_select_dropdown = 'multi_select_dropdown',
  number = 'number',
  radio_button = 'radio_button',
  phone = 'phone',
  email = 'email',
  consent = 'consent',
  name = 'name',
  work_email = 'work_email'
}

export enum RewardBannerType {
  paywallBanner = 'paywallBanner',
  splashBanner = 'splashBanner',
  checkoutBanner = 'checkoutBanner',
  leadFormBanner = 'leadFormBanner',
}

export interface Question {
  id?: Number
  questionLabel: string
  answerType: AnswerType
  answerLength: Number
  options: string[]
  isHidden: boolean
  isRequired: boolean
  isActive: boolean
}

export interface LeadForm {
  id?: number
  campaignId?: number
  description: string
  redirectUrl: string
  completedUrl: string
  failedUrl: string
  notificationUrl: string
  questions: Question[]
  internal: boolean
}
