import getRandomString from './getRandomString'
import { getItem, removeItem, setItem } from './localStorageFn'

const authTokenName = 'fcpAuthToken'
const refreshTokenName = 'fcpRefreshToken'
const deviceKey = 'fewcentDeviceKey'
let tempAuthToken: string = ''
let isAxiosCancelTokenEnable = false

const getTempAuthToken = (): string => tempAuthToken

const setTempAuthToken = (token?: string) => {
  if (!token) {
    tempAuthToken = ''
  } else {
    tempAuthToken = token
  }
}

const setAuthToken = (accessToken: string): void => setItem(authTokenName, accessToken)

const getAuthToken = (): any => {
  let authToken = getItem(authTokenName)
  if (authToken === 'null') {
    removeAuthToken()
    removeRefreshToken()
    return undefined
  } else {
    return authToken
  }
}

const hasAuthToken = (): boolean => {
  return !!getAuthToken()
}

const removeAuthToken = (): void => {
  removeItem(authTokenName)
}

const setRefreshToken = (refreshToken: string): void => setItem(refreshTokenName, refreshToken)

const getRefreshToken = (): any => {
  let refreshToken = getItem(refreshTokenName)
  if (refreshToken === 'null') {
    removeRefreshToken()
    return undefined
  } else {
    return refreshToken
  }
}

const removeRefreshToken = (): void => removeItem(refreshTokenName)

const getOrCreateDeviceIdentity = (): string => {
  const fewcentDeviceKey = getItem(deviceKey)

  if (fewcentDeviceKey) {
    return fewcentDeviceKey
  } else {
    const uid = getRandomString(15)
    setItem(deviceKey, uid)
    return uid
  }
}

const getDeviceIdentity = (): any => getItem(deviceKey)

const setDeviceIdentity = (value: string): void => setItem(deviceKey, value)

const removeDeviceIdentity = (): void => removeItem(deviceKey)

const setCancelTokenStatus = (status: boolean): void => {
  isAxiosCancelTokenEnable = status
}

const isAxiosTokenEnabled = (): boolean => isAxiosCancelTokenEnable

export {
  setAuthToken,
  getAuthToken,
  hasAuthToken,
  removeAuthToken,
  setRefreshToken,
  getRefreshToken,
  removeRefreshToken,
  getOrCreateDeviceIdentity,
  getDeviceIdentity,
  setDeviceIdentity,
  removeDeviceIdentity,
  setCancelTokenStatus,
  getTempAuthToken,
  setTempAuthToken,
  isAxiosTokenEnabled,
}
