import Logo from 'assets/svgs/blue-logo-transparent-bg.svg'
import Illustration2 from 'assets/svgs/sign-in-illustration-2.svg'
import 'components/Login/LoginBox/LoginBox.scss'
import { linkOutOwners } from 'containers/Linkout/linkout-service'
import 'containers/Login/Login.scss'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { getAuthToken, removeAuthToken, removeRefreshToken } from 'util/auth'
import { sendPublisherSlugAndCreatePublisher } from 'util/helper'
interface RouteParams {
  slug: string
}
const LoginCheck: FC = () => {
  const { t , i18n} = useTranslation()
  const history = useHistory()
  const [privacyPolicyCheck] = useState(true)
  const publisherSlug = useParams<RouteParams>().slug
  useEffect(() => {
    const accessToken = getAuthToken()
    const publisherSlugAndCreatePublisherParams = sendPublisherSlugAndCreatePublisher()
    if (!accessToken) {
      removeAuthToken()
      removeRefreshToken()

      history.push('/login')
    } else {
      if (publisherSlugAndCreatePublisherParams) {
        history.push(`/${publisherSlug}/linkout-editor?action=create&type=${linkOutOwners.Creator}`)
      } else {
        history.push('/dashboard')
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handlePrivacyPolicyCheck = () => {}

  const href = t('rootPage.href')
  const termsUrl = t('rootPage.termsUrl') 
  const privacyPolicyUrl = t('rootPage.privacyPolicyUrl')

  return (
    <div className="Login">
      <div className="LoginBox2__wrapper">
        <span className="LoginBox2__top-left-big-green"></span>
        <span className="LoginBox2__bottom-right-small-green"></span>
      </div>
      <div className="LoginBox2">
        <div className="LoginBox2__img-content">
          <div className="LoginBox2__logo-container">
            <img className="LoginBox2__logo" alt="logo" src={Logo} />
          </div>
          <img className="LoginBox2__img" alt="sign-in-illustration" src={Illustration2} />
        </div>
        <div className="LoginBox2__content">
          <h1 className="LoginBox2__title">{t('rootPage.instantAccess')}</h1>
          <p className="LoginBox2__description">
            {t('rootPage.noNeedToSubscribe')}
          </p>
          <div className="LoginBox2__terms-conditions-contact">
            <div className="LoginBox2__agree-terms">
              <input
                type="checkbox"
                id="privacy-policy"
                name="privacyPolicyCheck"
                checked={privacyPolicyCheck}
                onChange={handlePrivacyPolicyCheck}
              />
              <label
                className="LoginBox2__terms LoginBox2__privacy-policy"
                htmlFor="privacy-policy"
              >
                {t('rootPage.iAgreeToFewcents')}
                <a
                  className="LoginBox2__terms LoginBox2__terms--highlight"
                  href= {termsUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('rootPage.terms')}
                </a>
                {t('rootPage.and')}{' '}
                <a
                  className="LoginBox2__terms LoginBox2__terms--highlight"
                  href={privacyPolicyUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('rootPage.privacyPolicy')}
                </a>
                .
              </label>
            </div>
            <div className="LoginBox2__contact-us">
              <a
                className="LoginBox2__terms LoginBox2__terms--highlight"
                href= {href}
                target="_blank"
                rel="noreferrer"
              >
                {t('rootPage.emailSupport')}
              </a>
            </div>
          </div>
        </div>
        <span className="LoginBox2__bottom-right-big-pink"></span>
        <span className="LoginBox2__top-left-medium-pink"></span>
        <span className="LoginBox2__top-left-medium-green"></span>
      </div>
    </div>
  )
}

export default LoginCheck
