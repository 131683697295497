import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MailIcon from '@mui/icons-material/Mail'
import { Tooltip } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { FC, memo } from 'react'
import { Link } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'
import './LeftNavUser.scss'
import { useTranslation } from 'react-i18next'
import { hr } from 'date-fns/locale'

interface ComponentProps {
  fullMode: boolean
  onClose: (open: boolean) => void
  iconType?: any
}

const LeftNavUser: FC<ComponentProps> = ({ fullMode, onClose, iconType }) => {
  const name = useAppSelector((state) => state.user.user?.name)
  const { t } = useTranslation()
  const href = t('rootPage.href')
  return (
    <Grid item className="LeftNavUser">
      {iconType === 'support' ? (
        <a
          href={href}
          className="LeftNavUser__link"
          onClick={() => onClose(false)}
        >
          <Tooltip title={t('leftNav.supportUs') as string}>
            <MailIcon className="LeftNavUser__img" />
          </Tooltip>
          {fullMode && name && <span className="LeftNavUser__name">{t('leftNav.contactUs')}</span>}
        </a>
      ) : (
        <Link onClick={() => onClose(false)} to="/account" className="LeftNavUser__link">
          <Tooltip title={t('leftNav.userProfile') as string}>
            <AccountCircleIcon className="LeftNavUser__img" />
          </Tooltip>
          {fullMode && name && <span className="LeftNavUser__name">{name}</span>}
        </Link>
      )}
    </Grid>
  )
}

const MemoizedLeftNavUser = memo(LeftNavUser)

export default MemoizedLeftNavUser
