import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { ApiService } from 'services/apiService'
import { Campaign } from '../../interfaces/campaign.model'

interface CampaignSliceInterface {
  campaignData: Campaign[]
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: CampaignSliceInterface = {
  campaignData: [],
  inProcess: false,
  errors: null,
}

const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    setCampaign(state, action: PayloadAction<Campaign>) {
      state.campaignData = [...state.campaignData, action.payload]
    },
    setCampaignError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setCampaignProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
  },
})

export const { setCampaign, setCampaignError, setCampaignProgress } = campaignSlice.actions

export const getCampaign = createAsyncThunk(
  'traffic/getTrafficHistory',
  async (arg: { publisherId: number }, { dispatch }) => {
    dispatch(setCampaignProgress(true))

    try {
      const campaignPayload = {
        businessEntityId: 1,
        campaignAmount: 1100,
        campaignCode: '22',
        campaignCountry: 'IN',
        campaignCurrency: 'INR',
        campaignExpiryDate: '2022-01-30',
        campaignName: 'Jan Rewards',
        cardDescription: 'Rewards for the good Readers',
        cardLogoUrl: '',
        cardTitle: 'IN Rewards',
        claimButtonText: 'Claim',
        publisherIds: [],
      }
      const response = await ApiService.post<Campaign>(
        `/v1/b2c$RewardsCampaign/create`,
        campaignPayload
      )
      const { success, message, data } = response

      dispatch(setCampaignProgress(false))

      if (!success) {
        dispatch(setCampaignError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setCampaign(data))
      }
    } catch (ex: any) {
      dispatch(setCampaignProgress(true))
      dispatch(setCampaignError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export default campaignSlice
