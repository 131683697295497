import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import {
  addNewPublisher,
  getAllCurrencies,
  getAllPublisher,
  getLanguages,
} from 'store/slice/publisher-slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'

import Button from 'components/Custom/Button'
import CloseIcon from '@mui/icons-material/Close'
import { NewPublisher } from 'interfaces/newPublisher.model'
import { isValidDomainName } from 'util/helper'
import isEmail from 'validator/es/lib/isEmail'
import { ApiService } from 'services/apiService'
import './AddPublisher.scss'
import { useTranslation } from 'react-i18next'
interface ComponentProps extends DialogProps {
  onConfirm: () => void
  onCancel: () => void
  styleName?: string
  showScreen?: boolean
  onCancelScreen?: any
  setSuccessMessage?: any
  successMessage?: boolean
}

const helperText = { margin: 0 }

const AddNewPublisher: FC<ComponentProps> = ({
  onConfirm,
  onCancel,
  styleName,
  showScreen = false,
  onCancelScreen,
  successMessage,
  setSuccessMessage,
  ...props
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { publisherCurrenciesResponse } = useAppSelector((store) => store.publisher)
  const user = useAppSelector((store) => store.user.user)!
  const [suggestedSlugNames, setSuggestedSlugNames] = useState<any>([])
  const [isSlugNameExists, setIsSlugNameExists] = useState(false)
  const [allCurrencies, setAllCurrencies] = useState<any>([])
  const [allLanguages, setAllLanguages] = useState<any>([])
  const [newPublisher, setNewPublisher] = useState<NewPublisher>({
    name: '',
    bookCurrencyCode: '',
    domain: '',
    financeEmail: '',
    financePhone: '',
    createdByUserId: user.id,
    slug: '',
    primaryLanguage: 'en',
  })

  const [countBlur, setCountBlur] = useState(0)
  const [errors, setErrors] = useState<{ [k: string]: string } | null>(null)

  useEffect(() => {
    const getAllCurrenciesData = async () => {
      const response = await dispatch(getAllCurrencies())
      setAllCurrencies(response.payload)
    }

    getAllCurrenciesData()
  }, [user])

  useEffect(() => {
    const getLanguagesData = async () => {
      const response = await dispatch(getLanguages())
      setAllLanguages(response.payload)
    }
    getLanguagesData()
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    let localValue = value

    const publisher = newPublisher ?? ({} as NewPublisher)

    if (name === 'domain') {
      localValue = localValue.replace(/\s/g, '')
    }

    setNewPublisher({
      ...publisher,
      [name]: localValue,
    })
  }
  const handleChangeSlug = async (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target

    let userSlug = value

    const publisher = newPublisher ?? ({} as NewPublisher)

    setNewPublisher({
      ...publisher,
      [name]: userSlug,
    })
    const { data: publisherSlugNames } = await ApiService.get<any>(`/v1/publisher/publisherSlugs`)

    const isSlugExists = publisherSlugNames.includes(userSlug)
    setIsSlugNameExists(isSlugExists)

    const suggestedSlugs = generateRandomSlugNames(userSlug)
    setSuggestedSlugNames([...suggestedSlugs])
  }

  const handleBlur = async (e: { target: { name: any; value: any } }) => {
    setCountBlur(countBlur + 1)
    const { name, value } = e.target
    const nameRegex = (str: string) => {
      return str
        .replace('http://', '')
        .replace('https://', '')
        .replace('www.', '')
        .split(/[/?#]/)[0]
    }
    const publisher = newPublisher ?? ({} as NewPublisher)
    const userSlug = nameRegex(value)
      .substring(0, nameRegex(value).lastIndexOf('.'))
      .replaceAll('.', '-')

    setNewPublisher({
      ...publisher,
      [name]: nameRegex(value),
      slug: userSlug,
    })
    const { data: publisherSlugNames } = await ApiService.get<any>(`/v1/publisher/publisherSlugs`)

    const isSlugExists = publisherSlugNames.includes(userSlug)
    setIsSlugNameExists(isSlugExists)

    const suggestedSlugs = generateRandomSlugNames(userSlug)
    setSuggestedSlugNames([...suggestedSlugs])
  }

  const handleCurrencyChange = (e: SelectChangeEvent) => {
    const name = e.target.name!
    const value = e.target.value

    const publisher = newPublisher ?? ({} as NewPublisher)

    setNewPublisher({ ...publisher, [name]: value })
  }

  const handleLanguageChange = (e: SelectChangeEvent) => {
    const name = e.target.name!
    const value = e.target.value
    const publisher = newPublisher ?? ({} as NewPublisher)
    setNewPublisher({ ...publisher, [name]: value })
  }

  const generateRandomSlugNames = (text: string) => {
    const suggestedSlugs = [
      text.concat(String(Math.floor(Math.random() * 1000))),
      text.concat(String(Math.ceil(Math.random() * 10000))),
      text.concat(String(Math.ceil(Math.random() * 2000))),
    ]
    return [...suggestedSlugs]
  }

  const isAddPublisherFormValid = (): boolean => {
    const allowedChars = /^[a-zA-Z0-9\s]*$/
    let isValid = true

    setErrors(null)

    if (!(newPublisher?.name || '').trim()) {
      setErrors((prevState) => ({ ...prevState, name: t('addPublisher.publisherIsRequired') }))
      isValid = false
    }

    if (!allowedChars.test(newPublisher?.name)) {
      setErrors((prevState) => ({
        ...prevState,
        name: t('addPublisher.specialCharactersNotAllowed'),
      }))
      isValid = false
    }

    if (!newPublisher?.bookCurrencyCode) {
      setErrors((prevState) => ({
        ...prevState,
        bookCurrencyCode: t('addPublisher.currencyIsRequired'),
      }))
      isValid = false
    }

    if (!newPublisher?.domain) {
      setErrors((prevState) => ({ ...prevState, domain: t('addPublisher.domainIsRequired') }))
      isValid = false
    }

    if (newPublisher?.domain && !isValidDomainName(newPublisher.domain)) {
      setErrors((prevState) => ({ ...prevState, domain: t('addPublisher.DomainIsNotValid') }))
      isValid = false
    }
    if (newPublisher?.slug && isSlugNameExists) {
      setErrors((prevState) => ({
        ...prevState,
        slug: t('addPublisher.userFriendlyAliasNotUnique'),
      }))
      isValid = false
    }
    if (!newPublisher?.slug) {
      setErrors((prevState) => ({
        ...prevState,
        slug: t('addPublisher.userFriendlyAliaIsRequired'),
      }))
      isValid = false
    }

    if (!newPublisher?.financePhone) {
      setErrors((prevState) => ({
        ...prevState,
        financePhone: t('addPublisher.phoneNumberIsRequired'),
      }))
      isValid = false
    }

    if (newPublisher.financeEmail && !isEmail(newPublisher.financeEmail)) {
      setErrors((prevState) => ({ ...prevState, financeEmail: t('addPublisher.emailIsNotValid') }))
      isValid = false
    }

    return isValid
  }

  const handleSubmit = async () => {
    const isValid = isAddPublisherFormValid()

    if (!isValid) {
      return
    }
    const response = await dispatch(addNewPublisher(newPublisher)).unwrap()
    if (response) {
      await dispatch(getAllPublisher({ email: user.email }))
      onCancel()
      setSuccessMessage((successMessage: boolean) => !successMessage)
    }
  }

  const handleClose = () => {
    onCancel()
  }

  const ErrorBlock: FC<{ name: string }> = ({ name }) => {
    const error = errors?.[name]

    return (
      <FormHelperText style={helperText} error={!!error}>
        {error}
      </FormHelperText>
    )
  }
  const onClickSlugSuggestionHandler = (text: string) => {
    const publisher = newPublisher ?? ({} as NewPublisher)
    setNewPublisher({
      ...publisher,
      slug: text,
    })
    setIsSlugNameExists((prev) => !prev)
  }
  const onCancelTabChange = (e: any) => {
    onCancelScreen(e, 'linkExistingPublisher')
  }
  return (
    <Dialog {...props} onClose={showScreen ? () => {} : onCancel} className={styleName}>
      <DialogTitle>
        <Box display="flex" flexDirection="row">
          <Box flexGrow="1">
            <label>
              <strong>{t('addPublisher.newPublisher')}</strong>
            </label>
          </Box>
          <Box>
            {!showScreen && (
              <IconButton size="small">
                <CloseIcon
                  fontSize="small"
                  onClick={showScreen ? (e) => onCancelTabChange(e) : handleClose}
                />
              </IconButton>
            )}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} className="publisher--content">
          <Grid item xs={12}>
            <TextField
              label={t('addPublisher.domain')}
              size="small"
              name="domain"
              variant="outlined"
              autoComplete="off"
              value={newPublisher?.domain}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ maxLength: 100 }}
              error={!!errors?.domain}
              helperText={<ErrorBlock name="domain" />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('addPublisher.userFriendlyAlias')}
              size="small"
              name="slug"
              variant="outlined"
              autoComplete="off"
              value={newPublisher?.slug}
              onChange={handleChangeSlug}
              inputProps={{ maxLength: 100 }}
              error={!!errors?.slug}
              helperText={<ErrorBlock name="slug" />}
              fullWidth
              disabled={Boolean(!newPublisher.slug) && countBlur === 0}
            />
          </Grid>
          {isSlugNameExists && (
            <Grid item xs={12} className="slug--suggestion">
              {
                <p className="red-text">
                  {t('addPublisher.userFriendlyAliasExist', { slug: newPublisher.slug })}
                </p>
              }

              <Stack direction="row" spacing={1}>
                <Chip
                  label={suggestedSlugNames[0]}
                  onClick={() => onClickSlugSuggestionHandler(suggestedSlugNames[0])}
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label={suggestedSlugNames[1]}
                  onClick={() => onClickSlugSuggestionHandler(suggestedSlugNames[1])}
                  variant="outlined"
                  size="small"
                />
                <Chip
                  label={suggestedSlugNames[2]}
                  onClick={() => onClickSlugSuggestionHandler(suggestedSlugNames[2])}
                  variant="outlined"
                  size="small"
                />
              </Stack>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl variant="outlined" margin="none" size="small" fullWidth>
              <InputLabel>Currency*</InputLabel>
              <InputLabel>{t('addPublisher.currency')}</InputLabel>
              <Select
                name="bookCurrencyCode"
                label={t('addPublisher.currency')}
                value={newPublisher?.bookCurrencyCode}
                onChange={handleCurrencyChange}
                error={!!errors?.bookCurrencyCode}
              >
                {allCurrencies &&
                  allCurrencies?.currencies?.map((currency: any) => (
                    <MenuItem value={currency.code} key={`currency_${currency.id}`}>
                      {currency.code}
                    </MenuItem>
                  ))}
              </Select>
              {!!errors?.bookCurrencyCode && (
                <FormHelperText error={!!errors?.bookCurrencyCode}>
                  {errors.bookCurrencyCode}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" margin="none" size="small" fullWidth>
              <InputLabel>{t('addPublisher.primaryLanguage')}*</InputLabel>
              <Select
                name="primaryLanguage"
                label={t('addPublisher.primaryLanguage')}
                value={newPublisher?.primaryLanguage}
                onChange={handleLanguageChange}
                defaultValue={'en'}
              >
                {allLanguages &&
                  allLanguages?.languages?.map((language: any) => (
                    <MenuItem value={language.code} key={`currency_${language.code}`}>
                      {language.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography>{t('addPublisher.note')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('addPublisher.publisherName')}
              size="small"
              name="name"
              variant="outlined"
              autoComplete="off"
              onChange={handleChange}
              error={!!errors?.name}
              helperText={<ErrorBlock name="name" />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('addPublisher.phone')}
              size="small"
              name="financePhone"
              variant="outlined"
              autoComplete="off"
              onChange={handleChange}
              error={!!errors?.financePhone}
              helperText={<ErrorBlock name="financePhone" />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={t('addPublisher.email')}
              size="small"
              name="financeEmail"
              variant="outlined"
              autoComplete="off"
              onChange={handleChange}
              error={!!errors?.financeEmail}
              helperText={<ErrorBlock name="financeEmail" />}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={showScreen ? (e) => onCancelTabChange(e) : onCancel}>
            {t('addPublisher.cancel')}
          </Button>
          <Button variant="contained" color="primary" className="FloatRight" onClick={handleSubmit}>
            {t('addPublisher.save')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default AddNewPublisher
