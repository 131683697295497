import { createAsyncThunk } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { UpdatePaymentMethodModel } from './ManagePaymentMethod-types'

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

interface UpdatePaymentMethodArgs {
  inputData: UpdatePaymentMethodModel
}

export const getAllPaymentMethods = createAsyncThunk<any>('publisherCategories/get', async () => {
  const toasterId = 'getPublisherCategoriesToaster'
  try {
    const url = `/v1/admin/payment_method`
    const response = await ApiService.get<any>(url)
    const { data, success, message } = response
    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
    return data
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
})

export const UpdatePaymentMethod = createAsyncThunk<any, UpdatePaymentMethodArgs, RejectValue>(
  'reward/EditPublisherRewardCampaignDetails',
  async (args: UpdatePaymentMethodArgs, { rejectWithValue }) => {
    const { inputData } = args
    const toasterId = toast.loading('Editing Payment Method Details...')

    try {
      const response = await ApiService.put<any>(`/v1/admin/payment_method`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getEmptyReward = () => ({
  id: 0,
  gateway: '',
  gatewayId: '',
  gatewayDisplayName: '',
  paymentMethodType: '',
  showPpsMessage: false,
  ppsMessage: '',
  isActive: false,
  isFavorite: false,
  country: '',
  currency: '',
})
