import { FC, useEffect, useState } from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material'
import { disableButtons } from 'config'
import useRoleAuthorization from 'hooks/useRoleAuthorization'

interface ComponentProps extends ButtonProps {}

const Button: FC<ComponentProps> = ({ disabled, children, ...props }) => {
  const [isDisabled, setIsDisabled] = useState<boolean | undefined>(disabled)

  const { isActionBtnDisabled } = useRoleAuthorization()
  const actionBtnDisableStatus = isActionBtnDisabled()

  useEffect(() => {
    if (disableButtons || actionBtnDisableStatus) {
      setIsDisabled((prev) => true)
    } else {
      setIsDisabled((prev) => disabled)
    }
  }, [disabled, actionBtnDisableStatus])

  return (
    <MuiButton disabled={isDisabled} {...props}>
      {children}
    </MuiButton>
  )
}

export default Button
