import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { PublisherCampaignsWithOrdinalityType } from './publisherOrdinality-types'
import { getEmptyReward, getPublisherCampaignsWithOrdinality } from './publisherOrdinality-service'

const initialState: PublisherCampaignsWithOrdinalityType = {
  list: [],
  getPublisherCampaignsWithOrdinalityInProcess: false,

  errors: null,
  selectedSponsor: getEmptyReward(),
}

const publisherOrdinalitySlice = createSlice({
  name: 'publisherOrdinality',
  initialState,
  reducers: {
    setSelectedSponsor(state, action: PayloadAction<any>) {
      state.selectedSponsor = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPublisherCampaignsWithOrdinality.pending, (state) => {
        state.getPublisherCampaignsWithOrdinalityInProcess = true
      })
      .addCase(getPublisherCampaignsWithOrdinality.fulfilled, (state, action) => {
        state.getPublisherCampaignsWithOrdinalityInProcess = false
        state.list = action.payload
      })
      .addCase(getPublisherCampaignsWithOrdinality.rejected, (state, action) => {
        state.getPublisherCampaignsWithOrdinalityInProcess = false
        // state.errors = action?.payload
      })
  },
})

export const { setSelectedSponsor } = publisherOrdinalitySlice.actions
export default publisherOrdinalitySlice
