import { createSlice } from '@reduxjs/toolkit'
import { changeLinkoutActiveState, getLinkoutAsset } from './linkout-service'
import { LinkoutSliceType } from './linkout-types'

const initialState: LinkoutSliceType = {
  linkoutAssetModel: null,
  list: [],
  inProcess: false,
  errors: null,
}

const linkoutSlice = createSlice({
  name: 'linkout',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLinkoutAsset.pending, (state) => {
        state.inProcess = true
      })
      .addCase(getLinkoutAsset.fulfilled, (state, action) => {
        state.inProcess = false
        state.list = action.payload
      })
      .addCase(getLinkoutAsset.rejected, (state, action) => {
        state.inProcess = false
        state.errors = action.payload ?? null
      })
      .addCase(changeLinkoutActiveState.fulfilled, (state, { payload }) => {
        const asset = state.list.find((x) => x.id === payload.id)
        if (asset) {
          asset.active = payload.active
        }
      })
  },
})

export default linkoutSlice
