import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { PublisherTrafficResponse, TrafficFilterModel } from 'interfaces/traffic.model'
import { ApiService } from 'services/apiService'

interface PublisherHistorySliceInterface {
  trafficData: PublisherTrafficResponse
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: PublisherHistorySliceInterface = {
  trafficData: {
    totalCount: 0,
    items: [],
  },
  inProcess: false,
  errors: null,
}

const trafficSlice = createSlice({
  name: 'traffic',
  initialState,
  reducers: {
    setTraffic(state, action: PayloadAction<PublisherTrafficResponse>) {
      state.trafficData = action.payload
    },
    setPublisherTrafficError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setPublisherTrafficProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
  },
})

export const { setTraffic, setPublisherTrafficProgress, setPublisherTrafficError } =
  trafficSlice.actions

export const getPublisherTraffic = createAsyncThunk(
  'traffic/getTrafficHistory',
  async (
    arg: {
      publisherId: number
      pageNumber: number
      size: number
      filter: TrafficFilterModel
    },
    { dispatch }
  ) => {
    dispatch(setPublisherTrafficProgress(true))

    const filter: TrafficFilterModel = {
      ...arg.filter,
      countryFilter: arg.filter.countryFilter === 'All' ? '' : arg.filter.countryFilter,
      categoryFilter: arg.filter.categoryFilter === 'All' ? '' : arg.filter.categoryFilter,
      monetizeType: arg.filter.monetizeType === 'All' ? null : arg.filter.monetizeType,
    }

    try {
      const response = await ApiService.post<PublisherTrafficResponse>(
        `/v1/publisher/${arg.publisherId}/history/${arg.pageNumber}/${arg.size}`,
        filter
      )

      const { success, message, data } = response

      dispatch(setPublisherTrafficProgress(false))

      if (!success) {
        dispatch(setPublisherTrafficError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setTraffic(data))
      }
    } catch (ex: any) {
      dispatch(setPublisherTrafficProgress(true))
      dispatch(setPublisherTrafficError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export default trafficSlice
