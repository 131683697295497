import { Role } from 'interfaces/role.enum'
import screenKeyStore from './screenKeyWallet'

export interface RoleRuleModel {
  screen: string
  view: string[]
  update: string[]
}

const appRoleAuthStore: RoleRuleModel[] = [
  {
    screen: screenKeyStore.admin.customizeUI,
    view: [Role.SuperAdmin, Role.Admin, Role.Finance],
    update: [Role.SuperAdmin, Role.Admin, Role.Finance],
  },
  {
    screen: screenKeyStore.admin.commercials,
    view: [Role.SuperAdmin, Role.Admin, Role.Finance],
    update: [Role.SuperAdmin, Role.Finance, Role.Admin],
  },
  {
    screen: screenKeyStore.admin.setting,
    view: [Role.SuperAdmin, Role.Admin, Role.Finance],
    update: [Role.SuperAdmin, Role.Admin, Role.Finance],
  },
  {
    screen: screenKeyStore.admin.keys,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.admin.publisherFeature,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.admin.payout,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.admin.linkUser,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.admin.fewcentsCategories,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.dashboard.acceptBid,
    view: [Role.SuperAdmin],
    update: [Role.SuperAdmin],
  },
  {
    screen: screenKeyStore.dashboard.customerRefund,
    view: [Role.SuperAdmin],
    update: [Role.SuperAdmin],
  },
  {
    screen: screenKeyStore.dashboard.activatePublisher,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.dashboard.businessRewards,
    view: [Role.SuperAdmin],
    update: [Role.SuperAdmin],
  },

  {
    screen: screenKeyStore.dashboard.manageSponsor,
    view: [Role.SuperAdmin],
    update: [Role.SuperAdmin],
  },

  {
    screen: screenKeyStore.dashboard.managePaymentMethod,
    view: [Role.SuperAdmin],
    update: [Role.SuperAdmin],
  },

  {
    screen: screenKeyStore.dashboard.addPublisher,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
  {
    screen: screenKeyStore.linkout.approve,
    view: [Role.SuperAdmin, Role.Admin],
    update: [Role.SuperAdmin, Role.Admin],
  },
]

export default appRoleAuthStore
