import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'interfaces/user.model'

interface userSliceModel {
  user: User | null
}

const initialState: userSliceModel = {
  user: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload
    },
  },
})

export const { setUser } = userSlice.actions

export default userSlice
