import './LeftNav.scss'

import {
  Box,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { FC, useCallback, useEffect } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents'
import {
  setLeftSideBarFullMode,
  setLeftSidebarOpen,
  toggleLeftSideBarFullMode,
  toggleLeftSidebarOpen,
} from 'store/slice/application-slice'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import AddNewPublisher from './AddPublisher'
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import LeftNavUser from './LeftNavUser'
import { Link } from 'react-router-dom'
import Logo from '../../assets/svgs/wallet-navbar-logo.svg'
import PaidIcon from '@mui/icons-material/Paid'
import RootLevelScreens from './RootLevelScreens'
import SideMenu from './SideMenu'
import SmallLogo from '../../assets/svgs/CardLogo-test.svg'
import clsx from 'clsx'
import { makeStyles } from 'hooks/makeStyles'
import screenKeyStore from 'constants/screenKeyWallet'
import { useModal } from 'mui-modal-provider'
import useRoleAuthorization from 'hooks/useRoleAuthorization'
import usePublisher from 'hooks/usePublisher'
import ResourceMenu from './ResourceMenu'
import ArticleIcon from '@mui/icons-material/Article'
import { useTranslation } from 'react-i18next'
import { Role } from 'interfaces/role.enum'
import { ro } from 'date-fns/locale'

const drawerWidth = 250

const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: 'white !important',
  },
  bigAvatar: {
    width: 200,
  },
  smallAvatar: {
    width: 40,
  },
  parentNested: {
    paddingLeft: theme.spacing(4),
    color: 'white !important',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    margin: theme.spacing(1, 0),
    padding: `${theme.spacing(0, 1)} !important`,
    color: 'white',
  },
  flexCenter: {
    justifyContent: 'center',
  },
  toolbarBtn: {
    color: 'white !important',
  },
  iconColor: {
    color: 'white !important',
  },
  listItemText: {
    paddingLeft: theme.spacing(1),
    marginLeft: '2px',
  },
  iconSymbol: {
    marginTop: '2px',
  },
}))

const LeftNav: FC = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()
  const dispatch = useAppDispatch()
  const { showModal } = useModal()
  const theme = useTheme()
  const isMediumDevice = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const leftSidebarOpen = useAppSelector((state) => state.application.leftSidebarOpen)
  const leftSideBarFullMode = useAppSelector((state) => state.application.leftSideBarFullMode)
  const { isPublishersInProcess, publishersLoaded } = useAppSelector((state) => state.publisher)
  const { publishers, selectedPublisher } = usePublisher()
  useEffect(() => {
    if (isMediumDevice) {
      dispatch(setLeftSideBarFullMode(true))
    }
  }, [dispatch, isMediumDevice])
  const handleDrawerToggle = useCallback(() => {
    if (isMediumDevice) {
      dispatch(toggleLeftSidebarOpen())
    } else {
      dispatch(toggleLeftSideBarFullMode())
    }
  }, [dispatch, isMediumDevice])

  const addPublisher = () => {
    const modal = showModal(AddNewPublisher, {
      onConfirm: () => {
        modal.hide()
      },
      onCancel: () => {
        modal.hide()
      },
    })
  }

  const handleOnClose = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    dispatch(setLeftSidebarOpen(open))
  }

  const handleOnMenuClose = (open: boolean) => {
    if (!isMediumDevice) {
      return
    }

    dispatch(setLeftSidebarOpen(open))
  }
  const { canView: canViewAcceptBid, canUpdate: canUpdateAcceptBid } = useRoleAuthorization(
    screenKeyStore.dashboard.acceptBid
  )

  const { canView: canViewCustomerRefund, canUpdate: canUpdateCustomerRefund } =
    useRoleAuthorization(screenKeyStore.dashboard.customerRefund)

  const { canView: canViewActivatePublisher, canUpdate: canUpdateActivatePublisher } =
    useRoleAuthorization(screenKeyStore.dashboard.activatePublisher)

  const { canView: canViewBusinessRewards, canUpdate: canUpdateBusinessRewards } =
    useRoleAuthorization(screenKeyStore.dashboard.businessRewards)

  const { canView: canViewManageSponsor, canUpdate: canUpdateManageSponsor } = useRoleAuthorization(
    screenKeyStore.dashboard.manageSponsor
  )

  const { canView: canViewManagePaymentMethod, canUpdate: canUpdateManagePaymentMethod } =
    useRoleAuthorization(screenKeyStore.dashboard.managePaymentMethod)

  const { canView: isShowAddPublisherBtn } = useRoleAuthorization(
    screenKeyStore.dashboard.addPublisher
  )
  return (
    <Drawer
      open={leftSidebarOpen}
      onClose={handleOnClose(false)}
      variant={isMediumDevice ? 'temporary' : 'permanent'}
      className={cx(classes.drawer, {
        [classes.drawerOpen]: leftSideBarFullMode,
        [classes.drawerClose]: !leftSideBarFullMode,
      })}
      classes={{
        paper: cx({
          [classes.drawerPaper]: true,
          [classes.drawerOpen]: leftSideBarFullMode,
          [classes.drawerClose]: !leftSideBarFullMode,
        }),
      }}
    >
      <div
        className={clsx(classes.toolbar, {
          [classes.flexCenter]: !leftSideBarFullMode,
        })}
      >
        <IconButton onClick={handleDrawerToggle} className={classes.toolbarBtn}>
          {leftSideBarFullMode ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Grid container direction={'column'} height={'200%'}>
        <Grid item container justifyContent="center" alignItems="center">
          <Link onClick={() => handleOnMenuClose(false)} to="/dashboard" key="dashboard">
            {leftSideBarFullMode ? (
              <img
                className={classes.bigAvatar}
                alt="Fewcents"
                src={Logo}
                title={t('leftNav.dashboard') as string}
              />
            ) : (
              <Box m={6}>
                <Tooltip arrow title={t('leftNav.dashboard') as string}>
                  <img className={classes.smallAvatar} alt="Fewcents" src={SmallLogo} />
                </Tooltip>
              </Box>
            )}
          </Link>
        </Grid>
        {publishersLoaded && publishers?.length === 0 && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.gettingStarted')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.gettingStarted')}
              icon={<InfoIcon />}
              screenRoute={'/gettingStarted'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={true}
            />
          </Grid>
        )}
        {isShowAddPublisherBtn && (
          <Grid item className="MenuPublisher">
            <List disablePadding>
              <ListItem
                button
                className={classes.parentNested}
                key="add-publisher"
                onClick={addPublisher}
              >
                <ListItemIcon className={classes.iconColor}>
                  <div className={classes.iconSymbol}>
                    <Tooltip arrow title={t('leftNav.addPublisher') as string}>
                      <AddToPhotosIcon />
                    </Tooltip>{' '}
                  </div>
                  {leftSideBarFullMode && (
                    <ListItemText
                      className={classes.listItemText}
                      primary={t('leftNav.addPublisher')}
                    />
                  )}
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
        )}
        {canViewAcceptBid && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.acceptBid')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.acceptBid')}
              icon={<CheckCircleIcon />}
              screenRoute={'/acceptBid'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateAcceptBid}
            />
          </Grid>
        )}
        {canViewCustomerRefund && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.customerRefund')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.customerRefund')}
              icon={<PaidIcon />}
              screenRoute={'/customerRefund'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateCustomerRefund}
            />
          </Grid>
        )}
        {canViewActivatePublisher && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.activatePublisher')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.activatePublisher')}
              icon={<ArticleIcon />}
              screenRoute={'/activatePublisher'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateActivatePublisher}
            />
          </Grid>
        )}
        {canViewBusinessRewards && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.manageRewards')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.manageRewards')}
              icon={<EmojiEventsIcon />}
              screenRoute={'/businessRewards'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateBusinessRewards}
            />
          </Grid>
        )}

        {canViewManageSponsor && (
          <Grid>
            <RootLevelScreens
              name={t('leftNav.manageSponsor')}
              onClose={handleOnMenuClose}
              tooltipTitle={t('leftNav.manageSponsor')}
              icon={<EmojiEventsIcon />}
              screenRoute={'/sponsorManage'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateManageSponsor}
            />
          </Grid>
        )}

        {canViewManagePaymentMethod && (
          <Grid>
            <RootLevelScreens
              name={'Manage Payment Method'}
              onClose={handleOnMenuClose}
              tooltipTitle={'Manage Payment Method'}
              icon={<PaidIcon />}
              screenRoute={'/managePaymentMethod'}
              leftSideBarFullMode={leftSideBarFullMode}
              canUpdate={canUpdateManagePaymentMethod}
            />
          </Grid>
        )}

        {/* RESOURCE CENTER */}
        {publishers.length === 0 && <ResourceMenu />}
        {/* -- */}
        <Grid item container flexGrow={2} justifyContent={'center'}>
          {isPublishersInProcess && <Loading />}
          {publishers.length === 0 && !isPublishersInProcess && <NoPublisher />}
          {publishers.length > 0 && !isPublishersInProcess && (
            <SideMenu
              publishers={publishers}
              selectedPublisher={selectedPublisher}
              fullMode={leftSideBarFullMode}
              handleDrawerToggle={handleDrawerToggle}
              onClose={handleOnMenuClose}
            />
          )}
        </Grid>
        <div className="profile__section">
          <Grid item container justifyContent={'center'} p={2}>
            <LeftNavUser
              fullMode={leftSideBarFullMode}
              onClose={handleOnMenuClose}
              iconType="support"
            />
          </Grid>
          <Grid item container justifyContent={'center'} p={2}>
            <LeftNavUser fullMode={leftSideBarFullMode} onClose={handleOnMenuClose} />
          </Grid>
        </div>
      </Grid>
    </Drawer>
  )
}

const Loading = () => {
  const { t } = useTranslation()

  return <Grid item>{t('leftNav.loading')}...</Grid>
}

const NoPublisher = () => {
  const { t } = useTranslation()

  return (
    <Grid item p={1}>
      <Typography>{t('leftNav.noPublisher')}</Typography>
    </Grid>
  )
}

export default LeftNav
