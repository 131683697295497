import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

interface UpdateArgs {
  inputData: any
}
interface AcceptBidInterface {
  acceptBid: any | null
  acceptBidInProcess: boolean
  errors: { [k: string]: string } | null
}
const initialState: AcceptBidInterface = {
  acceptBid: null,
  errors: null,
  acceptBidInProcess: false,
}
export const updateAcceptBid = createAsyncThunk<any, UpdateArgs, RejectValue>(
  'acceptBid/updateAcceptBid',
  async (args: UpdateArgs, { rejectWithValue }) => {
    const { inputData } = args
    const toasterId = toast.loading('Updating accept Bid details...')

    try {
      const response = await ApiService.put<any>(`/v2/fbid/forceAcceptBid`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

const acceptBidSlice = createSlice({
  name: 'acceptBid',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // updateAcceptBid
    builder.addCase(updateAcceptBid.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.acceptBid.id = action.payload.id
      }
    })
  },
})

export default acceptBidSlice
