import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import {
  AnalyticsArticleCategoriesResponse,
  AnalyticsCountryDataResponse,
  AnalyticsRequestFilter,
  AnalyticsResponseData,
  ArticleCategories,
} from 'interfaces/analytics.model'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'

interface AnalyticsCountryDataSliceInterface {
  analyticsCountryData: AnalyticsCountryDataResponse
  analyticsArticleCategories: ArticleCategories[]
  analyticsData: AnalyticsResponseData | null
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: AnalyticsCountryDataSliceInterface = {
  analyticsCountryData: {
    distinctCountries: [],
  },
  inProcess: false,
  errors: null,
  analyticsArticleCategories:  [],
  analyticsData: null,
}

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    setCountryDataAnalytics(state, action: PayloadAction<AnalyticsCountryDataResponse>) {
      state.analyticsCountryData = action.payload
    },
    setAnalyticsArticleCategories(
      state,
      action
    ) {
      state.analyticsArticleCategories = action.payload
    },

    setAnalyticsData(state, action: PayloadAction<AnalyticsResponseData | null>) {
      state.analyticsData = action.payload
    },

    setAnalyticsCountryDataError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setAnalyticsCountryDataProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
  },
})

export const {
  setAnalyticsData,
  setCountryDataAnalytics,
  setAnalyticsArticleCategories,
  setAnalyticsCountryDataProgress,
  setAnalyticsCountryDataError,
} = analyticsSlice.actions

export const getAnalyticsCountryData = createAsyncThunk(
  'analytics/getAnalyticsCountryData',
  async (arg: { publisherId: number }, { dispatch }) => {
    dispatch(setAnalyticsCountryDataProgress(true))
    try {
      const response = await ApiService.get<AnalyticsCountryDataResponse>(
        `/v1/publisher/${arg.publisherId}/distinctBidCountries`
      )
      const { success, message, data } = response
      dispatch(setAnalyticsCountryDataProgress(false))

      if (!success) {
        dispatch(setAnalyticsCountryDataError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setCountryDataAnalytics(data))
      }
    } catch (ex: any) {
      dispatch(setAnalyticsCountryDataProgress(true))
      dispatch(setAnalyticsCountryDataError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const getAnalyticsArticleCategories = createAsyncThunk(
  'analytics/getAnalyticsArticleCategories',
  async (arg: { publisherId: number }, { dispatch }) => {
    dispatch(setAnalyticsCountryDataProgress(true))
    try {
      const response = await ApiService.get<AnalyticsArticleCategoriesResponse>(
        `/v1/publisher/${arg.publisherId}/articleCategories`
      )
      const { success, message, data } = response
      dispatch(setAnalyticsCountryDataProgress(false))

      if (!success) {
        dispatch(setAnalyticsCountryDataError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setAnalyticsArticleCategories(data))
      }
    } catch (ex: any) {
      dispatch(setAnalyticsCountryDataProgress(true))
      dispatch(setAnalyticsCountryDataError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const getAnalyticsData = createAsyncThunk(
  'analytics/getAnalyticsData',
  async (arg: { filter: AnalyticsRequestFilter }, { dispatch }) => {
    const toasterId = 'toast-getAnalyticsData'
    dispatch(setAnalyticsData(null))
    dispatch(setAnalyticsCountryDataProgress(true))
    const filter = {
      ...arg.filter,
      country: arg.filter.country === 'All' ? null : arg.filter.country,
      category: arg.filter.category === 'All' ? null : arg.filter.category,
    }
    const url = '/v1/publisher/accounting'
    try {
      const response = await ApiService.post<AnalyticsResponseData>(url, filter)
      const { success, message, data } = response
      dispatch(setAnalyticsCountryDataProgress(false))
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        dispatch(setAnalyticsCountryDataError({ error: errorMessage }))
      } else {
        dispatch(setAnalyticsData(data))
      }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      dispatch(setAnalyticsCountryDataProgress(true))
      toast.error(errorMessage, { id: toasterId })
      dispatch(setAnalyticsCountryDataError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export default analyticsSlice
