import { JWTDecodeModel } from 'interfaces/jwtDecode.model'
import jwt_decode from 'jwt-decode'
import { removeAuthToken, removeRefreshToken } from './auth'

const decodeToken = (token: string) => {
  try {
    const decoded = jwt_decode<JWTDecodeModel>(token)

    return decoded
  } catch {
    removeAuthToken()
    removeRefreshToken()

    return undefined
  }
}

export default decodeToken
