import config from 'config'
import { setAllPublisher } from 'store/slice/publisher-slice'
import { removeAuthToken, removeRefreshToken } from './auth'

const handleApiErrors = (response: Response) => {
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Unauthorized')
    }
  }
  return response
}

const manualLogout = () => {
  removeAuthToken()
  removeRefreshToken()
  setAllPublisher([])
  window.location.href = config.Domain
}

export { handleApiErrors, manualLogout }
