import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { SetupUserInterfaceResponse } from 'interfaces/response/setupUserInterfaceResponse.model'
import { SetupPaywall } from 'interfaces/setupPaywall.model'
import { SetupTipjar } from 'interfaces/setupTipjar.model'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'

interface SetupUserInterfaceState {
  paywall: SetupPaywall | null
  tipjar: SetupTipjar | null
  inProcess: boolean
  saveInProcess: boolean
  errors: { [k: string]: string } | null
}

export const getUserInterfaceSetting = createAsyncThunk<
  SetupUserInterfaceResponse,
  {
    publisherId: number
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'setupUserInterface/getUserInterfaceSetting',
  async (args: { publisherId: number }, { rejectWithValue }) => {
    try {
      const { publisherId } = args

      const paywallSettingRequest = ApiService.get<SetupPaywall>(
        `/v1/publisher/${publisherId}/paywall/settings`
      )
      const tipjarSettingRequest = ApiService.get<SetupTipjar>(
        `/v1/publisher/${publisherId}/tipjar/settings`
      )

      const [paywallResponse, tipjarResponse] = await Promise.all([
        paywallSettingRequest,
        tipjarSettingRequest,
      ])

      return {
        paywall: paywallResponse.data,
        tipjar: tipjarResponse.data,
      }
    } catch (ex: any) {
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

export const savePaywallSetting = createAsyncThunk<
  SetupPaywall,
  {
    publisherId: number
    paywallSetting: SetupPaywall
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'setupUserInterface/savePaywallSetting',
  async (args: { publisherId: number; paywallSetting: SetupPaywall }, { rejectWithValue }) => {
    const toasterId = toast.loading('Saving paywall setting...')

    try {
      const { publisherId, paywallSetting } = args

      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/paywall/settings/update`,
        paywallSetting
      )

      if (!response.success) {
        toast.error(response.message, { id: toasterId })

        return rejectWithValue({ 'Server Error:': response.message || COMMON_FAILURE })
      }

      toast.success('Paywall setting saved successfully.', { id: toasterId })

      return paywallSetting
    } catch (ex: any) {
      toast.error('Failed to save paywall setting.', { id: toasterId })
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

export const saveTipjarSetting = createAsyncThunk<
  SetupTipjar,
  {
    publisherId: number
    tipjarSetting: SetupTipjar
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'setupUserInterface/saveTipjarSetting',
  async (args: { publisherId: number; tipjarSetting: SetupTipjar }, { rejectWithValue }) => {
    const toasterId = toast.loading('Saving Tipjar Setting...')

    try {
      const { publisherId, tipjarSetting } = args

      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/tipjar/settings/update`,
        tipjarSetting
      )

      if (!response.success) {
        toast.error(response.message, { id: toasterId })

        return rejectWithValue({ 'Server Error:': response.message || COMMON_FAILURE })
      }

      toast.success('Tipjar setting saved successfully.', { id: toasterId })

      return tipjarSetting
    } catch (ex: any) {
      toast.error('Failed to save tipjar setting.', { id: toasterId })
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

const initialState: SetupUserInterfaceState = {
  paywall: null,
  tipjar: null,
  inProcess: false,
  saveInProcess: false,
  errors: null,
}

const setupUserInterface = createSlice({
  name: 'setupUserInterface',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getUserInterfaceSetting
    builder
      .addCase(getUserInterfaceSetting.pending, (state) => {
        state.inProcess = true
        state.paywall = null
        state.tipjar = null
      })
      .addCase(getUserInterfaceSetting.fulfilled, (state, { payload }) => {
        state.inProcess = false
        state.paywall = payload.paywall
        state.tipjar = payload.tipjar
      })
      .addCase(getUserInterfaceSetting.rejected, (state, { payload }) => {
        state.inProcess = false
        state.paywall = null
        state.tipjar = null
        state.errors = payload ?? null
      })

    //savePaywallSetting
    builder
      .addCase(savePaywallSetting.pending, (state) => {
        state.saveInProcess = true
      })
      .addCase(savePaywallSetting.fulfilled, (state, { payload }) => {
        state.saveInProcess = false
        state.paywall = payload
      })
      .addCase(savePaywallSetting.rejected, (state, { payload }) => {
        state.saveInProcess = false
        state.errors = payload ?? null
      })

    //saveTipjarSetting
    builder
      .addCase(saveTipjarSetting.pending, (state) => {
        state.saveInProcess = true
      })
      .addCase(saveTipjarSetting.fulfilled, (state, { payload }) => {
        state.saveInProcess = false
        state.tipjar = payload
      })
      .addCase(saveTipjarSetting.rejected, (state, { payload }) => {
        state.saveInProcess = false
        state.errors = payload ?? null
      })
  },
})

export default setupUserInterface
