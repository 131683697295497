interface Environment {
  [x: string]: Configuration
}

interface Configuration {
  ApiBaseUrl: string
  SDKUrl: string
  Domain: string
  linkoutUrl: string
  SegmentWriteKey?: string
  DisableButtons?: boolean
  EnableQuickSightDashboards: boolean
  GoogleAppId: string
}

const configs: Environment = {
  dev: {
    ApiBaseUrl: 'https://api.hounds.fewcents.co',
    SDKUrl: 'https://dev.fewcents.co',
    Domain: window.location.host.includes('localhost')
      ? 'http://localhost:3001'
      : 'https://publisher.dev.fewcents.co',
    linkoutUrl: 'https://dev.fewcents.me',
    SegmentWriteKey: undefined,
    DisableButtons: false,
    EnableQuickSightDashboards: true,
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
  },
  qa: {
    ApiBaseUrl: 'https://api.qa.fewcents.co',
    SDKUrl: 'https://paywall.qa.fewcents.co',
    Domain: 'https://partner.qa.fewcents.co',
    linkoutUrl: 'https://qa.fewcents.me',
    SegmentWriteKey: undefined,
    DisableButtons: false,
    EnableQuickSightDashboards: false,
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
  },
  demo: {
    ApiBaseUrl: 'https://api.demo.fewcents.co',
    SDKUrl: 'https://staging.fewcents.co',
    Domain: 'https://publisher.staging.fewcents.co',
    linkoutUrl: 'https://staging.fewcents.me',
    SegmentWriteKey: undefined,
    DisableButtons: false,
    EnableQuickSightDashboards: false,
    GoogleAppId: '52683014478-7b6790vtstromr5aookelnhad356k0j9.apps.googleusercontent.com',
  },
  prod: {
    ApiBaseUrl: 'https://api-publisher.fewcents.co',
    SDKUrl: 'https://paywall.fewcents.co',
    Domain: 'https://partner.fewcents.co',
    linkoutUrl: 'https://fewcents.me',
    SegmentWriteKey: undefined,
    DisableButtons: false,
    EnableQuickSightDashboards: false,
    GoogleAppId: '52683014478-8i20g3lsae6h1kal220emv7i61d644hp.apps.googleusercontent.com',
  },
}

const environment = process.env.REACT_APP_STAGE?.trim() || 'dev'
const config = configs[environment]

export const ApiBaseUrl = config.ApiBaseUrl
export const disableButtons = config.DisableButtons
export const isProductionEnvironment = environment === 'prod'

const getEnvironmentName = (environment: string) => {
  let envName = ''

  if (environment === 'dev') {
    envName = 'Development'
  } else if (environment === 'demo') {
    envName = 'Staging'
  }

  return envName
}

export const environmentName = getEnvironmentName(environment)

export default config
