import { getOrCreateDeviceIdentity } from 'util/auth'
import config from '../config'
const Analytics: any = require('@segment/analytics.js-core/build/analytics')
const SegmentIntegration: any = require('@segment/analytics.js-integration-segmentio')

declare global {
  interface Window {
    analytics: any
  }
}

const analytics = new Analytics()
analytics.use(SegmentIntegration)
const integrationSettings = {
  'Segment.io': {
    apiKey: config.SegmentWriteKey,
    retryQueue: true,
    addBundledMetadata: true,
  },
}

export function initialize() {
  if (config.SegmentWriteKey) {
    analytics.initialize(integrationSettings)
  }
}

export function track(...args: any[]) {
  const defaultProperties = {
    component: 'wallet',
    componentVersion: '1',
    deviceKey: getOrCreateDeviceIdentity(),
  }
  /** Assign default properties to all events */
  if (args[1]) {
    Object.assign(args[1], defaultProperties)
    if (args[1].fewCentsBid?.customerInfo) {
      /** Filter out customerInfo as it contains sensitive data */
      delete args[1].fewCentsBid.customerInfo
    }
  } else {
    args[1] = defaultProperties
  }
  if (config.SegmentWriteKey) {
    analytics.track(...args)
  }
}

export function identify(...args: any[]) {
  if (config.SegmentWriteKey) {
    analytics.identify(...args)
  }
}
