import React, { FC, ReactNode } from 'react'
import './Layout.scss'
import { Container } from '@mui/material'
import { makeStyles } from 'hooks/makeStyles'
interface ComponentProps {
  children: ReactNode
  className?: string
}
const useStyles = makeStyles()((theme) => ({
  main: {
    width: '100%',
    background: 'white',
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100vh',
  },
}))

const Layout: FC<ComponentProps> = ({ children }) => {
  const { classes } = useStyles()

  return (
    <Container maxWidth="xl" component="main" className={classes.main}>
      <div className="ContentLayout">{children}</div>
    </Container>
  )
}

export default Layout
