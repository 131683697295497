import {
  Autocomplete,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Tooltip,
} from '@mui/material'
import { NavLink, useHistory } from 'react-router-dom'

import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import ArticleIcon from '@mui/icons-material/Article'
import CategoryIcon from '@mui/icons-material/Category'
import { FC, useEffect } from 'react'
import LocalAtmIcon from '@mui/icons-material/LocalAtm'
import { Publisher } from 'interfaces/publisher.model'
import SearchIcon from '@mui/icons-material/Search'
import SettingsIcon from '@mui/icons-material/Settings'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { makeStyles } from 'hooks/makeStyles'
import screenKeyStore from 'constants/screenKeyWallet'
import { useAppSelector } from 'store/hooks'
import useRoleAuthorization from 'hooks/useRoleAuthorization'
import { useDispatch } from 'react-redux'
import { setPublisherSelected } from 'store/slice/publisher-slice'
import config from 'config'
import { useTranslation } from 'react-i18next'
import { getPublisherCampaignsWithOrdinality } from 'components/PublisherOrdinality/publisherOrdinality-service'

interface ComponentProps {
  publishers: Publisher[]
  selectedPublisher: Publisher | null
  fullMode: boolean
  handleDrawerToggle: () => void
  onClose: (open: boolean) => void
}

interface NavProps {
  visible: boolean
  title: string
  to: string
  icon: JSX.Element
  fullMode: boolean
  onClose: (open: boolean) => void
}

const autoCompleteStyle = () => {
  return {
    '& .MuiInputLabel-root': { color: 'white !important' },
    '& .MuiOutlinedInput-root': {
      '& fieldset': { borderColor: 'white !important' },
      color: 'white',
      '& > fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& > fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiIconButton-root': {
      color: 'white',
    },
  }
}

const useStyles = makeStyles()((theme) => ({
  textField: {
    color: 'white',
  },
  nestedMenuItem: {
    paddingLeft: '3.5rem !important',
    color: 'white !important',
  },
  nestedMenuItemMiniMode: {
    paddingLeft: `${theme.spacing(3)} !important`,
  },
  iconColor: {
    color: 'white !important',
  },
  iconBtn: {
    color: 'white',
  },
}))

const AddNavLink: FC<NavProps> = ({ visible, title, to, icon, fullMode, onClose }) => {
  const { classes, cx } = useStyles()

  return visible ? (
    <NavLink onClick={() => onClose(false)} className={'navLink'} to={to}>
      <ListItem
        button
        className={cx('listItem', {
          [classes.nestedMenuItem]: fullMode,
          [classes.nestedMenuItemMiniMode]: !fullMode,
        })}
      >
        <ListItemIcon className={classes.iconColor}>
          <Tooltip arrow title={title}>
            {icon}
          </Tooltip>
        </ListItemIcon>
        {fullMode && <ListItemText primary={title} />}
      </ListItem>
    </NavLink>
  ) : null
}

const SideMenu: FC<ComponentProps> = ({
  publishers,
  selectedPublisher,
  fullMode,
  handleDrawerToggle,
  onClose,
}) => {
  const { t } = useTranslation()
  const publisherOrdinalityList = useAppSelector((store) => store.publisherOrdinality.list)
  const { classes } = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const enableCampaign = useAppSelector((state) => state.globalData.enableCampaign)
  const { canView: canViewCustomizeUI } = useRoleAuthorization(screenKeyStore.admin.customizeUI)
  const { canView: canViewCommercials } = useRoleAuthorization(screenKeyStore.admin.commercials)
  const { canView: canViewSetting } = useRoleAuthorization(screenKeyStore.admin.setting)
  const { canView: canViewKeys } = useRoleAuthorization(screenKeyStore.admin.keys)
  const { canView: canViewPublisherFeature } = useRoleAuthorization(
    screenKeyStore.admin.publisherFeature
  )
  const { canView: canViewPayout } = useRoleAuthorization(screenKeyStore.admin.payout)
  const { canView: canViewLinkUser } = useRoleAuthorization(screenKeyStore.admin.linkUser)
  const { canView: canViewFewcentsCategories } = useRoleAuthorization(
    screenKeyStore.admin.fewcentsCategories
  )
  const isAdminMenuVisible =
    canViewCustomizeUI ||
    canViewCommercials ||
    canViewSetting ||
    canViewPublisherFeature ||
    canViewPayout ||
    canViewKeys ||
    canViewLinkUser ||
    canViewFewcentsCategories
  const features = selectedPublisher?.feature
  const handleOnChange = (event: any, selectedItem: Publisher | null) => {
    if (selectedItem) {
      let route
      dispatch(setPublisherSelected(selectedItem.slug))
      if (window.location.pathname !== '/dashboard') {
        const subpath = window.location.pathname.split('/').slice(2)
        route = `/${selectedItem.slug}/${subpath.join('/')}${window.location.search}`
      } else {
        route = `/${selectedItem?.slug}/setup`
      }
      history.push(route)
    }
  }

  const handleSearchOnClick = () => {
    handleDrawerToggle()
  }

  const monetizeType = (selectedPublisher: Publisher) => {
    if (selectedPublisher?.feature?.paywallEnabled && selectedPublisher?.feature?.tipjarEnabled) {
      return selectedPublisher?.primaryMonetizeType ?? 'paywall'
    } else if (selectedPublisher?.feature?.paywallEnabled) {
      return 'paywall'
    } else if (selectedPublisher?.feature?.tipjarEnabled) {
      return 'tipjar'
    } else {
      return 'inactive'
    }
  }

  useEffect(() => {
    ;(async () => {
      if (selectedPublisher) {
        const getPublisherOrdinalityList = async () => {
          await dispatch(getPublisherCampaignsWithOrdinality({ publisherId: selectedPublisher.id }))
        }
        getPublisherOrdinalityList()
      }
    })().catch((error) => console.error(error))
  }, [selectedPublisher])

  return (
    <Grid item container direction={'column'} mt={2}>
      <Grid item container p={1} justifyContent={'center'}>
        {fullMode ? (
          <Autocomplete
            value={selectedPublisher}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, value) => handleOnChange(event, value)}
            getOptionLabel={(option) => option.domain}
            options={publishers}
            selectOnFocus
            fullWidth
            renderOption={(props, option: Publisher) => <li {...props}>{option.domain}</li>}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                autoComplete="off"
                sx={autoCompleteStyle}
                variant="outlined"
                label={t('sideMenu.selectPublisher')}
              />
            )}
          />
        ) : (
          <Tooltip arrow title={t('SelectPublisher') as string}>
            <IconButton
              aria-label={t('SelectPublisher') as string}
              className={classes.iconBtn}
              onClick={handleSearchOnClick}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      {selectedPublisher && (
        <Grid item>
          <List>
            <AddNavLink
              visible={isAdminMenuVisible}
              title={t('sideMenu.admin')}
              to={`/${selectedPublisher.slug}/admin`}
              icon={<AdminPanelSettingsIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={true}
              title={t('sideMenu.setup')}
              to={`/${selectedPublisher.slug}/setup`}
              icon={<SettingsIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={true}
              title={t('sideMenu.onboardingScript')}
              to={`/${selectedPublisher.slug}/onboardingScript`}
              icon={<SettingsIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />


            <AddNavLink
              visible={features!.paywallEnabled || features!.tipjarEnabled}
              title={t('sideMenu.category')}
              to={`/${selectedPublisher.slug}/category?monetizeType=${monetizeType(
                selectedPublisher
              )}`}
              icon={<CategoryIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={features!.paywallEnabled || features!.tipjarEnabled}
              title={t('sideMenu.content')}
              to={`/${selectedPublisher.slug}/content?monetizeType=${monetizeType(
                selectedPublisher
              )}`}
              icon={<ArticleIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={features!.paywallEnabled || features!.tipjarEnabled}
              title={t('sideMenu.balance')}
              to={`/${selectedPublisher.slug}/balance`}
              icon={<LocalAtmIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={features!.paywallEnabled || features!.tipjarEnabled}
              title={t('sideMenu.summary')}
              to={`/${selectedPublisher.slug}/analytics?selectedOption=${monetizeType(
                selectedPublisher
              )}`}
              icon={<TrendingUpIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={config.EnableQuickSightDashboards}
              title={t('sideMenu.statistics')}
              to={`/${selectedPublisher.slug}/statistics`}
              icon={<TrendingUpIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={features!.socialLinkoutEnabled || features!.fewcentsCheckoutEnabled}
              title={t('sideMenu.linkout')}
              to={`/${selectedPublisher.slug}/linkout`}
              icon={<AccountBalanceIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />

            <AddNavLink
              visible={enableCampaign}
              title={t('sideMenu.campaign')}
              to={`/${selectedPublisher.slug}/campaign`}
              icon={<AccountBalanceIcon className={'navLink--icon'} />}
              fullMode={fullMode}
              onClose={onClose}
            />
            {publisherOrdinalityList.length > 0 && (
              <AddNavLink
                visible={!enableCampaign}
                title={t('leftNav.rewardsOrdinality') as string}
                to={`/${selectedPublisher.slug}/publisherOrdinality`}
                icon={<AccountBalanceIcon className={'navLink--icon'} />}
                fullMode={fullMode}
                onClose={onClose}
              />
            )}
          </List>
        </Grid>
      )}
    </Grid>
  )
}

export default SideMenu
