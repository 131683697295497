import axios from 'axios'
import { getNextToken } from 'components/Login/LoginBox/getNextToken'
import { User } from 'interfaces/user.model'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { setUser } from 'store/slice/user-slice'
import { getAuthToken } from 'util/auth'
import decodeToken from 'util/decodeToken'
import { manualLogout } from 'util/handleApiErrors'
import { ApiService } from './../../services/apiService'

const useFetchCustomer = (): [string, boolean] => {
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const user = useAppSelector((state) => state.user.user)
  const accessToken = getAuthToken()

  useEffect(() => {
    let mounted = true
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    ;(async () => {
      if (!user && accessToken) {
        const decoded: any = decodeToken(accessToken)
        if (decoded.exp * 1000 <= Date.now()) {
          await getNextToken()
        }
        const newAccessToken = getAuthToken()
        const newDecoded: any = decodeToken(newAccessToken)

        setLoading(true)

        try {
          const response = await ApiService.get<User>(`/v1/user/${newDecoded.username}`, {
            cancelToken: source.token,
          })

          const { data, message, success } = response

          if (!success)
            throw new Error(message ?? 'Something went wrong. failed to process request')

          dispatch(setUser(data))
        } catch (e: any) {
          if (e?.message?.includes('Unauthorized')) manualLogout()

          if (mounted) {
            setError(e?.message)
            setLoading(false)
          }
        }
      }
    })()

    return () => {
      mounted = false
      source.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return [error, loading]
}

export default useFetchCustomer
