const validateTipjarPrices = (prices: number[] | string[]): boolean => {
  return (
    prices[0] > 0 &&
    prices[1] >= Number(prices[0]) * 1.5 &&
    Number(prices[2]) >= Number(prices[1]) * 1.67
  )
}

const isDecimalNumberValid = (text: string) => {
  const onlyNumberWithSingleDot = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$')

  if (onlyNumberWithSingleDot.test(text)) {
    if (text.indexOf('.') !== text.length - 1) {
      const decimalCheckWithTwoDigit = new RegExp(/^\d*(\.\d{1,2})?$/)

      return decimalCheckWithTwoDigit.test(text)
    } else {
      return true
    }
  }

  return false
}

const isDecimal = (text: string) => {
  const regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g)

  return regex.test(text)
}

const isNumber = (text: string) => {
  const regExp = /^\d+$/

  return regExp.test(text)
}

const isNumberWithNegative = (text: string) => {
  const regExp = /^-?\d+$/

  return regExp.test(text)
}

const isOnlyTextAndNumber = (text: string) => {
  const regExp = new RegExp('^[a-zA-Z0-9]+$')

  return regExp.test(text)
}

const isValidDomainName = (text: string) => {
  const stringArray = text.split('.')

  return (stringArray.length > 1 && stringArray.every((item) => isOnlyTextAndNumber(item))) || '-'
}

const isNull = <T>(value: T | null | undefined): value is null => {
  return value === null
}

const isUndefined = <T>(value: T | null | undefined): value is undefined => {
  return value === undefined
}

const isNullOrUndefined = <T>(value: T | null | undefined) => {
  return value === null || value === undefined
}

const isNullOrEmptyOrUndefined = (value: string | null | undefined | number) => {
  return value === null || value === undefined || value === ''
}

const sendPublisherSlugAndCreatePublisher = () => {
  if (
    !isNullOrEmptyOrUndefined(sessionStorage.getItem('publisherSlug')) &&
    !isNullOrEmptyOrUndefined(sessionStorage.getItem('createPublisher'))
  ) {
    let publisherSlugAndCreatePublisherParams = {} as any
    publisherSlugAndCreatePublisherParams.publisherSlug = sessionStorage.getItem('publisherSlug')
    const createPublisher = sessionStorage.getItem('createPublisher')
    if (createPublisher === 'true') {
      publisherSlugAndCreatePublisherParams.autoCreatePublisher = true
    } else if (createPublisher === 'false') {
      publisherSlugAndCreatePublisherParams.autoCreatePublisher = false
    }
    return publisherSlugAndCreatePublisherParams
  }
}
const removeSessionStorageItems = (value1: any, value2: any) => {
  sessionStorage.removeItem(value1)
  sessionStorage.removeItem(value2)
}

const isValidLink = (url: string) => {
  let regexp = /^(http|https):\/\//;
   return regexp.test(url)
}
export {
  validateTipjarPrices,
  isDecimal,
  isDecimalNumberValid,
  isOnlyTextAndNumber,
  isNumber,
  isNumberWithNegative,
  isValidDomainName,
  isNull,
  isUndefined,
  isNullOrUndefined,
  isNullOrEmptyOrUndefined,
  removeSessionStorageItems,
  sendPublisherSlugAndCreatePublisher,
  isValidLink,
}
