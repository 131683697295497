import { JWTDecodeModel } from 'interfaces/jwtDecode.model'
import { Role } from 'interfaces/role.enum'
import { useEffect, useState } from 'react'
import { getAuthToken } from 'util/auth'
import decodeToken from 'util/decodeToken'
import appRoleAuthStore from '../constants/roleAuthorizeRule'

const useRoleAuthorization = (screenKey: string = '') => {
  const token = getAuthToken()

  let decoded: JWTDecodeModel | undefined | null = null

  if (token) {
    decoded = decodeToken(token)
  }

  const [canView, setCanView] = useState<boolean>(false)
  const [canUpdate, setCanUpdate] = useState<boolean>(false)

  useEffect(() => {
    if (decoded) {
      const userRoles = decoded['cognito:groups'] ?? ([] as string[])
      const view = appRoleAuthStore.find(
        (x) => x.screen === screenKey && x.view.some((y) => userRoles.includes(y))
      )

      if (view) {
        setCanView((prev) => (prev = true))
        const update = appRoleAuthStore.find(
          (x) => x.screen === screenKey && x.update.some((y) => userRoles.includes(y))
        )

        if (update) {
          setCanUpdate((prev) => (prev = true))
        }
      }
    }
  }, [decoded, screenKey])

  const isActionBtnDisabled = () => {
    const userRoles = (decoded?.['cognito:groups'] ?? []) as string[]
    const readOnlyRoles = [Role.Publisher, Role.ReadOnly]

    return (
      readOnlyRoles.every((x) => userRoles.includes(x)) && userRoles.length === readOnlyRoles.length
    )
  }

  return {
    canView,
    canUpdate,
    isActionBtnDisabled,
  }
}

export default useRoleAuthorization
