/*
ApiService singleton service for fewCents

response object structure from api service
{ data: {}, status: 200, statusText: 'OK', config: {}, request: {} }
"data" is the response that was provided by the server
"status" is the HTTP status code from the server response
"statusText" is the HTTP status message from the server response
"headers" the HTTP headers that the server responded with
All header names are lower cased and can be accessed using the bracket notation.
Example: "response.headers['content-type']"

"config" is the config object that was provided to "axios" for the request
"request" is an XMLHttpRequest instance in the browser
*/

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiResponse } from 'interfaces/response/baseResponse.model'
import { getAuthToken, hasAuthToken } from 'util/auth'
import { ApiBaseUrl } from '../config'

// axios.defaults.baseURL = ApiBaseUrl;
// axios.defaults.headers.common['Content-Type'] = 'application/json';

const axiosInstance = axios.create({
  baseURL: ApiBaseUrl,
  timeout: 1000 * 60 * 2,
  responseType: 'json',
  validateStatus: function (status) {
    return status <= 500 // default
  },
})

// Add interceptor to add Auth-token
axiosInstance.interceptors.request.use(
  (config) => {
    if (hasAuthToken()) {
      config.headers['Authorization'] = `Bearer ${getAuthToken()}`
    }
    return config
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      throw new Error('Unauthorized')
    }

    return response
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data)
    }
    return Promise.reject(error.message)
  }
)

// Singleton service
class FewcentsApiService {
  private static instance: FewcentsApiService
  private axios: AxiosInstance

  responseBody = <T>(response: AxiosResponse<T>) => response.data

  // constructor private for singleton
  private constructor() {
    this.axios = axiosInstance
  }

  // returns singleton instance of class
  public static getInstance(): FewcentsApiService {
    if (!this.instance) {
      this.instance = new FewcentsApiService()
    }
    return this.instance
  }

  get<T>(url: string, config: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
    return this.axios.get<ApiResponse<T>>(url, config).then(this.responseBody)
  }

  post<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.post<ApiResponse<T>>(url, payload, config).then(this.responseBody)
  }

  put<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.put<ApiResponse<T>>(url, payload, config).then(this.responseBody)
  }

  patch<T>(
    url: string,
    payload?: { [k: string]: any },
    config: AxiosRequestConfig = {}
  ): Promise<ApiResponse<T>> {
    return this.axios.patch<ApiResponse<T>>(url, payload, config).then(this.responseBody)
  }

  delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<ApiResponse<T>> {
    return this.axios.delete<ApiResponse<T>>(url, config).then(this.responseBody)
  }
}

export const ApiService = FewcentsApiService.getInstance()
