import { createAsyncThunk } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'

export const businessRewardSections = {
  MainSection: 'mainSection',
  SubSection: 'subSection',
}

interface SaveRewardArgs {
  inputData: any
}

interface SponsorBusinessManageEditArgs {
  inputData: any
  businessId: number
}

interface SponsorBusinessManageAddArgs {
  inputData: any
}

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

export const getSponsorBusinessManage = createAsyncThunk<any>(
  'sponsorBusinessManage/get',
  async () => {
    const toasterId = 'getRewardsToaster'
    try {
      const url = `/v1/reward/business`
      const response = await ApiService.get<any>(url)
      const { data, success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const saveRewardCampaignDetails = createAsyncThunk<any, SaveRewardArgs, RejectValue>(
  'reward/saveCampaign',
  async (args: SaveRewardArgs, { rejectWithValue }) => {
    const { inputData } = args
    const { campaignId, ...postData } = inputData
    const toasterId = toast.loading('Saving campaign details...')
    try {
      const response = campaignId
        ? await ApiService.put<any>(`/v1/reward/campaign/${campaignId}`, postData)
        : await ApiService.post<any>(`/v1/reward/campaign`, postData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const AddNewSponsorDetails = createAsyncThunk<
  any,
  SponsorBusinessManageAddArgs,
  RejectValue
>(
  'sponsorBusinessManage/AddNewSponsorDetails',
  async (args: SponsorBusinessManageAddArgs, { rejectWithValue }) => {
    const { inputData } = args
    const toasterId = toast.loading('Adding New Sponsor Business Details...')

    try {
      const response = await ApiService.post<any>(`/v1/reward/business`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const EditSponsorDetails = createAsyncThunk<any, SponsorBusinessManageEditArgs, RejectValue>(
  'reward/EditPublisherRewardCampaignDetails',
  async (args: SponsorBusinessManageEditArgs, { rejectWithValue }) => {
    const { inputData } = args
    const { businessId } = args
    const toasterId = toast.loading('Editing Sponsor Business Details...')
    try {
      const response = await ApiService.put<any>(`/v1/reward/business/${businessId}`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getEmptyReward = () => ({
  id: 0,
  name: '',
  logoUrl: '',
  notificationUrl: '',
  termsConditionsHtml: '',
  signedRequest: true,
})
