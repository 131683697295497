import { FC, ReactElement, useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import useTokenExpiryCheck from './useTokenExpiryCheck'

interface ComponentProps {
  children: ReactElement
  path: string | string[]
  roles?: string[]
}

const InternalRoute: FC<ComponentProps> = ({ children, path, roles = [], ...rest }) => {
  const decoded = useTokenExpiryCheck()

  const isAuthenticated = useMemo(() => {
    if (!decoded) {
      return false
    }

    if (roles.length) {
      const userRoles = decoded['cognito:groups'] ?? ([] as string[])

      return roles.some((role) => userRoles.includes(role))
    }

    return true
  }, [decoded, roles])

  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <>{children}</>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default InternalRoute
