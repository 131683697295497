import { createAsyncThunk } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { PublisherCampaignsWithOrdinality } from './publisherOrdinality-types'

interface publisherCampaignsWithOrdinalityArgs {
  publisherId: number
  inputData: PublisherCampaignsWithOrdinality[]
}

interface publisherIdArg {
  publisherId: number
}

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

export const getPublisherCampaignsWithOrdinality = createAsyncThunk<any, publisherIdArg>(
  'ordinality/getPublisherCampaignsWithOrdinality',
  async (args: publisherIdArg) => {
    const { publisherId } = args
    const toasterId = 'getPublisherCategoriesToaster'
    try {
      const url = `/v1/reward/publisher/${publisherId}/ordinality`
      const response = await ApiService.get<any>(url)
      const { data, success, message } = response
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
      }
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
    }
  }
)

export const editPublisherCampaignsWithOrdinality = createAsyncThunk<
  any,
  publisherCampaignsWithOrdinalityArgs,
  RejectValue
>(
  'ordinality/editPublisherCampaignsWithOrdinality',
  async (args: publisherCampaignsWithOrdinalityArgs, { rejectWithValue }) => {
    const { inputData } = args
    const { publisherId } = args
    const toasterId = toast.loading('Editing Publisher Campaigns With Ordinality Details...')
    try {
      const response = await ApiService.put<any>(
        `/v1/reward/publisher/${publisherId}/ordinality`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)
export const getEmptyReward = () => ({
  publisherId: 0,
  campaignId: 0,
  campaignName: '',
  ordinality: 0,
})
