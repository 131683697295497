import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { Country, SubDivisionOne } from 'interfaces/countryWithSubdivision.model'
import { GeoFencing, GeoFencingSettingRequest } from 'interfaces/geoFencing.model'
import { GeoPricingSettingRequest } from 'interfaces/geoPricing.model'
import { GeoPricingSetting } from 'interfaces/geoPricingSetting.model'
import { CountryWithSubdivisionResponse } from 'interfaces/response/countryWithSubdivisionResponse.model'
import toast from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { RootState } from 'store'

type categoryGeographySliceType = {
  countries: Country[]
  selectedCountry: Country | null
  regionInProgress: boolean
  count: number
  countryInProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: categoryGeographySliceType = {
  countries: [],
  selectedCountry: null,
  regionInProgress: true,
  count: 0,
  countryInProcess: false,
  errors: null,
}

const categoryGeographySlice = createSlice({
  name: 'categoryGeography',
  initialState,
  reducers: {
    setCountries(state, action: PayloadAction<CountryWithSubdivisionResponse>) {
      const { count, countries } = action.payload
      state.countries = countries
      state.count = count
    },
    setSelectedCountry(state, action: PayloadAction<Country>) {
      state.selectedCountry = action.payload
      state.regionInProgress = false
    },
    setInProcess(state, action: PayloadAction<boolean>) {
      state.countryInProcess = action.payload
    },
    updateCountryGeoFencing(state, action: PayloadAction<Country>) {
      const country = action.payload

      const currentCountry = state.countries.find((x) => x.isoCode === country.isoCode)

      if (currentCountry) {
        currentCountry.isGeoFencingEnabled = country.isGeoFencingEnabled

        currentCountry.subDivisionOne.forEach((subDivisionOne) => {
          subDivisionOne.isGeoFencingEnabled = country.isGeoFencingEnabled
        })

        state.regionInProgress = false
        state.selectedCountry = currentCountry
      }
    },
    setCountrySelectAll(state, action: PayloadAction<boolean>) {
      state.selectedCountry = null

      state.countries.forEach((x) => {
        x.isGeoFencingEnabled = action.payload

        x.subDivisionOne.forEach((subdivision) => {
          subdivision.isGeoFencingEnabled = action.payload
        })
      })
    },
    resetSelectedCountry(state, action: PayloadAction) {
      state.selectedCountry = null
    },
    setGeographyError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setSubDivisionGeoFencing(
      state,
      action: PayloadAction<{ countryIsoCode: string; subDivision: SubDivisionOne }>
    ) {
      const { countryIsoCode, subDivision } = action.payload
      const selectedCountry = state.countries.find((x) => x.isoCode === countryIsoCode)
      if (selectedCountry) {
        const selectedSubDivision = selectedCountry.subDivisionOne.find(
          (x) => x.isoCode === subDivision.isoCode
        )

        if (selectedSubDivision) {
          selectedSubDivision.isGeoFencingEnabled = subDivision.isGeoFencingEnabled

          const isAnySubDivisionSelected = selectedCountry.subDivisionOne.some(
            (x) => x.isGeoFencingEnabled
          )

          selectedCountry.isGeoFencingEnabled = isAnySubDivisionSelected

          state.selectedCountry = selectedCountry
        }
      }
    },
    updateCountryPrice(state, action: PayloadAction<Country>) {
      const country = action.payload

      const selectedCountry = state.countries.find((x) => x.isoCode === country.isoCode)
      if (selectedCountry) {
        selectedCountry.geoPrice = country.geoPrice
      }
    },
    setSubDivisionGeoPricing(
      state,
      action: PayloadAction<{ countryIsoCode: string; subDivision: SubDivisionOne }>
    ) {
      const { countryIsoCode, subDivision } = action.payload
      const selectedCountry = state.countries.find((x) => x.isoCode === countryIsoCode)
      if (selectedCountry) {
        const selectedSubDivision = selectedCountry.subDivisionOne.find(
          (x) => x.isoCode === subDivision.isoCode
        )

        if (selectedSubDivision) {
          selectedSubDivision.geoPrice = subDivision.geoPrice
          state.selectedCountry = selectedCountry
        }
      }
    },
    setSubDivisionSelectAll(
      state,
      action: PayloadAction<{ countryISOCode: string; checked: boolean }>
    ) {
      const { countryISOCode, checked: isChecked } = action.payload

      const selectedCountry = state.countries.find((x) => x.isoCode === countryISOCode)

      if (selectedCountry) {
        if (selectedCountry.subDivisionOne.length > 0) {
          selectedCountry.isGeoFencingEnabled = isChecked

          selectedCountry.subDivisionOne.forEach((subDivision) => {
            subDivision.isGeoFencingEnabled = isChecked
          })
          state.selectedCountry = selectedCountry
        }
      }
    },
  },
})

export const {
  setCountries,
  setGeographyError,
  setInProcess,
  setSelectedCountry,
  setCountrySelectAll,
  resetSelectedCountry,
  updateCountryGeoFencing,
  setSubDivisionGeoFencing,
  setSubDivisionGeoPricing,
  setSubDivisionSelectAll,
  updateCountryPrice,
} = categoryGeographySlice.actions

export const getCountryWithGeographySection = createAsyncThunk(
  'geography/getCountryWithGeographySection',
  async (arg: { publisherId: number; categoryId: number; monetizeType: string }, { dispatch }) => {
    try {
      dispatch(setInProcess(true))

      const getCountries = ApiService.get<CountryWithSubdivisionResponse>(
        `/v1/geo/countryWithSubdivisions`
      )

      const getPublisherGeoFencingSettings = ApiService.get<GeoFencing[]>(
        `/v1/publisher/${arg.publisherId}/category/${arg.categoryId}/geoFencingSettings`
      )

      const getPublisherGeoPricingSettings = ApiService.get<GeoPricingSetting[]>(
        `/v1/publisher/${arg.publisherId}/category/${arg.categoryId}/geoPricingSettings`
      )

      const [countryResponse, publisherGeoFencingResponse, getPublisherGeoPricingSettingsResponse] =
        await Promise.all([
          getCountries,
          getPublisherGeoFencingSettings,
          getPublisherGeoPricingSettings,
        ])

      dispatch(setInProcess(false))

      if (
        !countryResponse.success ||
        !publisherGeoFencingResponse.success ||
        !getPublisherGeoPricingSettingsResponse.success
      ) {
        dispatch(setGeographyError('Can not fetch Geography Data'))
      } else {
        const countries = countryResponse.data.countries
        const publisherGeoFencing = publisherGeoFencingResponse.data
        const publisherGeoPricing = getPublisherGeoPricingSettingsResponse.data

        publisherGeoFencing
          .filter((x) => x.monetizeType === arg.monetizeType)
          .forEach((geoFencing) => {
            const country = countries.find((x) => x.isoCode === geoFencing.countryCode)
            if (country) {
              if (geoFencing.regionCode && geoFencing.countryCode) {
                const subDivision = country.subDivisionOne.find(
                  (x) => x.isoCode === geoFencing.regionCode
                )

                if (subDivision) {
                  subDivision.isGeoFencingEnabled = true
                }
              } else if (geoFencing.countryCode && !geoFencing.regionCode) {
                country.isGeoFencingEnabled = true
              }
            }
          })

        publisherGeoPricing
          .filter((x) => x.monetizeType === arg.monetizeType)
          .forEach((geoPricing) => {
            const country = countries.find((x) => x.isoCode === geoPricing.countryCode)
            if (country) {
              if (geoPricing.regionCode && geoPricing.countryCode) {
                const subDivision = country.subDivisionOne.find(
                  (x) => x.isoCode === geoPricing.regionCode
                )

                if (subDivision) {
                  subDivision.geoPrice = geoPricing.priceFactor
                }
              } else if (geoPricing.countryCode && !geoPricing.regionCode) {
                country.geoPrice = geoPricing.priceFactor
              }
            }
          })

        const { data } = countryResponse

        dispatch(setCountries(data))
      }
    } catch (ex: any) {
      dispatch(setInProcess(false))
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export const saveCategoryGeoFencing = createAsyncThunk<
  boolean,
  {
    publisherId: number
    categoryId: number
    monetizeType: string
    geoFencingEnabled: boolean
  },
  { state: RootState }
>(
  'geography/saveCategoryGeoFencing',
  async (
    args: {
      publisherId: number
      categoryId: number
      monetizeType: string
      geoFencingEnabled: boolean
    },
    { dispatch, getState }
  ) => {
    const toasterId = toast.loading('Saving Geo-Fencing...')

    try {
      const {
        categoryGeography: { countries },
      } = getState()

      const { monetizeType, publisherId, categoryId, geoFencingEnabled } = args

      const filterCountries = countries.filter((x) => {
        return (
          x.isGeoFencingEnabled ||
          x.subDivisionOne.some((s) => {
            return s.isGeoFencingEnabled
          })
        )
      })

      const geoFencingSettingRequest: GeoFencingSettingRequest = {
        geoFencingSetting: {
          geoFencingEnabled: geoFencingEnabled,
          monetizeType: monetizeType,
          geoFencingSets: [],
        },
      }

      filterCountries.forEach((country) => {
        const selectedSubdivision = country.subDivisionOne.filter((s) => s.isGeoFencingEnabled)

        if (selectedSubdivision.length > 0) {
          selectedSubdivision.forEach((sub) => {
            if (sub.isGeoFencingEnabled) {
              geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
                country: country.isoCode,
                region: sub.isoCode,
              })
            }
          })
        }

        if (country.isGeoFencingEnabled) {
          geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
            country: country.isoCode,
            region: '',
          })
        }
      })

      const updateGeoFencingSettingResponse = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/category/${categoryId}/updateGeoFencingSettings`,
        geoFencingSettingRequest
      )

      if (!updateGeoFencingSettingResponse.success) {
        const errorMessage = updateGeoFencingSettingResponse.message
        dispatch(setGeographyError({ 'Server Error:': errorMessage }))

        toast.error(errorMessage, { id: toasterId })

        return false
      } else {
        toast.success('Geo-Fencing saved.', { id: toasterId })

        return true
      }
    } catch (ex: any) {
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))

      toast.error('Failed to save Geo-Fencing.', { id: toasterId })

      return false
    }
  }
)

export const saveCategoryGeoPricing = createAsyncThunk<
  boolean,
  {
    publisherId: number
    categoryId: number
    monetizeType: string
    geoPricingEnabled: boolean
  },
  { state: RootState }
>(
  'geography/saveCategoryGeoPricing',
  async (
    args: {
      publisherId: number
      categoryId: number
      monetizeType: string
      geoPricingEnabled: boolean
    },
    { dispatch, getState }
  ) => {
    const toasterId = toast.loading('Saving Geo-Pricing...')

    try {
      const {
        categoryGeography: { countries },
      } = getState()

      const { monetizeType, publisherId, categoryId, geoPricingEnabled } = args

      const filterCountries = countries.filter((x) => {
        return (
          x.geoPrice ||
          x.subDivisionOne.some((s) => {
            return s.geoPrice
          })
        )
      })

      const geoPricingSettingRequest: GeoPricingSettingRequest = {
        geoPricingSetting: {
          geoPricingEnabled: geoPricingEnabled,
          geoPricingSets: [],
          monetizeType: monetizeType,
        },
        publisherId: publisherId,
      }

      filterCountries.forEach((country) => {
        const selectedSubdivision = country.subDivisionOne.filter((s) => s.geoPrice)

        if (selectedSubdivision.length > 0) {
          selectedSubdivision.forEach((sub) => {
            if (sub.geoPrice) {
              geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
                country: country.isoCode,
                region: sub.isoCode,
                priceFactor: sub.geoPrice,
              })
            }
          })
        }

        if (country.geoPrice) {
          geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
            country: country.isoCode,
            region: '',
            priceFactor: country.geoPrice,
          })
        }
      })

      const updateGeoPricingSettingResponse = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/category/${categoryId}/updateGeoPricingSettings`,
        geoPricingSettingRequest
      )

      if (!updateGeoPricingSettingResponse.success) {
        const errorMessage = updateGeoPricingSettingResponse.message

        dispatch(setGeographyError({ 'Server Error:': errorMessage }))

        toast.error(errorMessage, { id: toasterId })

        return false
      } else {
        toast.success('Geo-Pricing saved.', { id: toasterId })

        return true
      }
    } catch (ex: any) {
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))

      toast.error('Failed to save Geo-Pricing.', { id: toasterId })

      return false
    }
  }
)

export const saveContentGeographyData = createAsyncThunk<
  boolean,
  {
    publisherId: number
    categoryId: number
    monetizeType: string
    geoFencingEnabled: boolean
    geoPricingEnabled: boolean
  },
  { state: RootState }
>(
  'geography/saveContentGeographyData',
  async (
    args: {
      publisherId: number
      categoryId: number
      monetizeType: string
      geoFencingEnabled: boolean
      geoPricingEnabled: boolean
    },
    { dispatch, getState }
  ) => {
    const toasterId = toast.loading('Saving Geography Data...')

    try {
      const {
        categoryGeography: { countries },
      } = getState()

      const { monetizeType, publisherId, categoryId, geoFencingEnabled, geoPricingEnabled } = args

      const filterCountries = countries.filter((x) => {
        return (
          x.isGeoFencingEnabled ||
          x.geoPrice ||
          x.subDivisionOne.some((s) => {
            return s.isGeoFencingEnabled || s.geoPrice
          })
        )
      })

      const geoFencingSettingRequest: GeoFencingSettingRequest = {
        geoFencingSetting: {
          geoFencingEnabled: geoFencingEnabled,
          monetizeType: monetizeType,
          geoFencingSets: [],
        },
      }

      const geoPricingSettingRequest: GeoPricingSettingRequest = {
        geoPricingSetting: {
          geoPricingEnabled: geoPricingEnabled,
          geoPricingSets: [],
          monetizeType: monetizeType,
        },
        publisherId: publisherId,
      }

      filterCountries.forEach((country) => {
        const selectedSubdivision = country.subDivisionOne.filter(
          (s) => s.geoPrice || s.isGeoFencingEnabled
        )

        if (selectedSubdivision.length > 0) {
          selectedSubdivision.forEach((sub) => {
            if (sub.isGeoFencingEnabled) {
              geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
                country: country.isoCode,
                region: sub.isoCode,
              })
            }

            if (sub.geoPrice) {
              geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
                country: country.isoCode,
                region: sub.isoCode,
                priceFactor: sub.geoPrice,
              })
            }
          })
        }

        if (country.isGeoFencingEnabled) {
          geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
            country: country.isoCode,
            region: '',
          })
        }

        if (country.geoPrice) {
          geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
            country: country.isoCode,
            region: '',
            priceFactor: country.geoPrice,
          })
        }
      })

      const updateGeoFencingSettingRequest = ApiService.put<any>(
        `/v1/publisher/${publisherId}/category/${categoryId}/updateGeoFencingSettings`,
        geoFencingSettingRequest
      )

      const updateGeoPricingSettingRequest = ApiService.put<any>(
        `/v1/publisher/${publisherId}/category/${categoryId}/updateGeoPricingSettings`,
        geoPricingSettingRequest
      )

      const [updateGeoFencingSettingResponse, updateGeoPricingSettingResponse] = await Promise.all([
        updateGeoFencingSettingRequest,
        updateGeoPricingSettingRequest,
      ])

      if (!updateGeoFencingSettingResponse.success || !updateGeoPricingSettingResponse.success) {
        let errorMessage = ''

        if (!updateGeoFencingSettingResponse.success) {
          errorMessage += updateGeoFencingSettingResponse.message
        }

        if (!updateGeoPricingSettingResponse.success) {
          errorMessage += ' ' + updateGeoPricingSettingResponse.message
          errorMessage = errorMessage.trim()
        }

        dispatch(setGeographyError({ 'Server Error:': errorMessage }))

        toast.error(errorMessage, { id: toasterId })

        return false
      } else {
        toast.success('Geography data saved.', { id: toasterId })

        return true
      }
    } catch (ex: any) {
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))

      toast.error('Failed to save geography data.', { id: toasterId })

      return false
    }
  }
)

export default categoryGeographySlice
