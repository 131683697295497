import { createAsyncThunk, createSlice, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit'
import { FewcentsCategory } from 'components/Admin/AdminSection/FewcentsCategories'
import { CancelledPublishers } from 'components/LeftNav/ActivatePublisher'
import { COMMON_FAILURE } from 'constants/common'
import { MetaData } from 'interfaces/MetaData.model'
import { ApiResponse } from 'interfaces/response/baseResponse.model'
import { orderBy } from 'lodash'
import toast, { dispatch } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import {
  setSelectedPublisherFeatureData,
  setSelectedPublisherPrimaryMonetizeType,
} from './publisher-slice'

interface AdminSliceInterface {
  publisherFeatureSettingInProcess: any
  publisherFeatureSettingMetaData: any
  publisherFeatureSetting: any
  customSetting: any | null
  customSettingMetaData: MetaData[]
  customSettingInProcess: boolean
  customKeysSettingInProcess: boolean
  commercialSetting: any | null
  commercialSettingMetaData: MetaData[]
  commercialSettingInProcess: boolean
  publisherSetting: any | null
  publisherSettingMetaData: MetaData[]
  publisherSettingInProcess: boolean
  payoutSetting: any | null
  payoutSettingMetaData: MetaData[]
  payoutSettingInProcess: boolean
  linkUserSetting: any | null
  linkUserSettingMetaData: MetaData[]
  linkUserSettingInProcess: boolean
  fewcentsCategories: FewcentsCategory[] | null
  fewcentsCategoriesInProcess: boolean
  fewcentsCategoriesOfPublisher: FewcentsCategory[] | null
  fewcentsCategoriesOfPublisherInProcess: boolean
  selectedFewcentsCategories: FewcentsCategory[] | null
  cancelledPublishers: CancelledPublishers[] | null
  cancelledPublishersInProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: AdminSliceInterface = {
  customSetting: null,
  customSettingMetaData: [],
  customSettingInProcess: false,
  customKeysSettingInProcess: false,
  commercialSetting: null,
  commercialSettingMetaData: [],
  commercialSettingInProcess: false,
  publisherSetting: null,
  publisherSettingMetaData: [],
  publisherSettingInProcess: false,
  publisherFeatureSetting: null,
  publisherFeatureSettingMetaData: [],
  publisherFeatureSettingInProcess: false,
  payoutSetting: null,
  payoutSettingMetaData: [],
  payoutSettingInProcess: false,
  linkUserSetting: null,
  linkUserSettingMetaData: [],
  linkUserSettingInProcess: false,
  fewcentsCategories: null,
  fewcentsCategoriesInProcess: false,
  fewcentsCategoriesOfPublisher: null,
  fewcentsCategoriesOfPublisherInProcess: false,
  selectedFewcentsCategories: [],
  cancelledPublishers: null,
  cancelledPublishersInProcess: false,
  errors: null,
}
interface AdminSlicePostRequestArgs {
  publisherId: number
  inputData: any
}

interface AdminSliceGetRequestArgs {
  publisherId: number
}
interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}
export const getCustomSetting = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getCustomSetting', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getCustomSetting'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(`/v1/publisher/${publisherId}/admin/customSettings`)
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const updateCustomSetting = createAsyncThunk<any, AdminSlicePostRequestArgs, RejectValue>(
  'digitalAsset/updateCustomSetting',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Customize UI updating...')

    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/admin/updateCustomSettings`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })

        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getCommercialSetting = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getCommercialSetting', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getCommercialSetting'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(`/v1/publisher/${publisherId}/admin/commercials`)
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const updateCommercialSetting = createAsyncThunk<
  any,
  AdminSlicePostRequestArgs,
  RejectValue
>(
  'digitalAsset/updateCommercialSetting',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Commercials updating...')

    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/admin/updateCommercials`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })

        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getPublisherFeatureSetting = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/publisherFeatureSetting', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getPublisherFeatutreSetting'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(
      `/v1/publisher/${publisherId}/admin/publisherFeatureSettings`
    )
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const updatePublisherFeatureSetting = createAsyncThunk<any, AdminSlicePostRequestArgs>(
  'digitalAsset/updatePublisherFeatureSetting',
  async (args: AdminSlicePostRequestArgs, { dispatch }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Publisher Feature settings updating...')

    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/admin/updatePublisherFeatureSettings`,
        inputData
      )

      const { success, data, message } = response
      dispatch(setPublisherFeatureSettingData(data.feature))
      dispatch(setSelectedPublisherFeatureData(data.feature))

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        dispatch(setAdminError({ error: message || COMMON_FAILURE }))
        return { success: false, message: errorMessage }
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      dispatch(setAdminError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return { success: false, message: errorMessage }
    }
  }
)

export const updateTermsAndPolicy = createAsyncThunk<any, AdminSlicePostRequestArgs, RejectValue>(
  'digitalAsset/updatePayoutSetting',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Term and Policy updating...')

    try {
      const response = await ApiService.put<any>(`/v1/publisher/${publisherId}/admin/publisherTermsAndPolicy`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)


export const updatePayoutSetting = createAsyncThunk<any, AdminSlicePostRequestArgs, RejectValue>(
  'digitalAsset/updatePayoutSetting',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Payout settings updating...')

    try {
      const response = await ApiService.post<any>(`/v1/publisher/${publisherId}/payout`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)


export const getPublisherSettings = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getPublisherSettings', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getPublisherSettings'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(
      `/v1/publisher/${publisherId}/admin/publisherSettings`
    )
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const updatePublisherSetting = createAsyncThunk(
  'digitalAsset/updatePublisherSetting',
  async (args: { publisherId: number; publisherSetting: any }, { dispatch }) => {
    const { publisherId, publisherSetting } = args
    const toasterId = toast.loading('Publisher settings updating...')

    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/admin/updatePublisherSettings`,
        publisherSetting
      )
      const { success, data, message } = response
      dispatch(setPublisherSettingData(data))
      dispatch(setSelectedPublisherPrimaryMonetizeType(data))

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        dispatch(setAdminError({ error: message || COMMON_FAILURE }))
        return { success: false, message: errorMessage }
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      dispatch(setAdminError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
      return { success: false, message: errorMessage }
    }
  }
)

export const getCustomKeysSettings = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getCustomKeysSettings', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getCustomKeysSettings'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(`/v1/publisher/${publisherId}/admin/keys`)
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const updateLinkUserSetting = createAsyncThunk<any, AdminSlicePostRequestArgs, RejectValue>(
  'digitalAsset/updateLinkUserSetting',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Link User settings updating...')

    try {
      const response = await ApiService.post<any>(
        `/v1/publisher/${publisherId}/admin/linkUser`,
        inputData
      )
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getFewcentsCategories = createAsyncThunk<
  ApiResponse<FewcentsCategory[]> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getFewcentsCategories', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getFewcentsCategories'

  try {
    const { publisherId } = args
    const response = await ApiService.get<FewcentsCategory[]>(`/v1/publisher/fewCentsCategories`)
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})

export const getFewcentsCategoriesOfPublisher = createAsyncThunk<
  ApiResponse<FewcentsCategory[]> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>(
  'admin/getFewcentsCategoriesOfPublisher',
  async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
    const toastId = 'toaster-getFewcentsCategoriesOfPublisher'

    try {
      const { publisherId } = args
      const response = await ApiService.get<FewcentsCategory[]>(
        `/v1/publisher/${publisherId}/fewCentsCategories`
      )
      const { success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toastId })

        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const updateFewcentsCategories = createAsyncThunk<
  FewcentsCategory[],
  AdminSlicePostRequestArgs,
  RejectValue
>(
  'admin/updateFewcentsCategories',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const { publisherId, inputData } = args
    const toasterId = toast.loading('Fewcents Categories updating...')

    try {
      const response = await ApiService.put<FewcentsCategory[]>(
        `/v1/publisher/${publisherId}/fewCentsCategories/update`,
        inputData
      )

      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getCancelledPublishers = createAsyncThunk<any>(
  'admin/getCancelledPublishers',
  async () => {
    const toastId = 'toaster-getCancelledPublishers'

    try {
      const response = await ApiService.get<any>(`/v1/publisher/admin/getCancelledPublishers`)
      const { success, message } = response
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toastId })
      }

      return response
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })
    }
  }
)
export const activatePublisher = createAsyncThunk<any, AdminSlicePostRequestArgs, RejectValue>(
  'admin/activatePublisher',
  async (args: AdminSlicePostRequestArgs, { rejectWithValue }) => {
    const inputData = args
    const toasterId = toast.loading('Activating the publisher...')
    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${inputData}/admin/activatePublisher`
      )

      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE

        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })

      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const getPublisherTermsAndPolicy = createAsyncThunk<
  ApiResponse<any> | undefined,
  AdminSliceGetRequestArgs,
  RejectValue
>('admin/getPublisherTermsAndPolicy', async (args: AdminSliceGetRequestArgs, { rejectWithValue }) => {
  const toastId = 'toaster-getPublisherTermsAndPolicy'

  try {
    const { publisherId } = args
    const response = await ApiService.get<any>(`/v1/publisher/${publisherId}/admin/publisherTermsAndPolicy`)
    const { success, message } = response

    if (!success) {
      const errorMessage = message || COMMON_FAILURE
      toast.error(errorMessage, { id: toastId })

      return rejectWithValue({ 'Server Error:': errorMessage })
    }

    return response
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toastId })

    return rejectWithValue({ 'Server Error:': errorMessage })
  }
})


const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setPublisherSettingData(state, action) {
      state.publisherSetting.primaryMonetizeType = action.payload.primaryMonetizeType
    },
    setPublisherFeatureSettingData(state, action) {
      state.publisherFeatureSetting = action.payload
    },
    setAdminError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
  },
  extraReducers: (builder) => {
    //  getCustomSetting
    builder
      .addCase(getCustomSetting.pending, (state) => {
        state.customSetting = null
        state.customSettingMetaData = []
        state.customSettingInProcess = true
      })
      .addCase(getCustomSetting.fulfilled, (state, action) => {
        state.customSettingInProcess = false
        state.customSetting = action!.payload!.data
        state.customSettingMetaData = orderBy(action!.payload!.metadata, ['ordinality'], ['asc'])
      })
      .addCase(getCustomSetting.rejected, (state, action) => {
        state.customSettingInProcess = false
        state.customSetting = null
        state.customSettingMetaData = []
        state.errors = action.payload ?? null
      })

    // updateCustomSetting
    builder.addCase(updateCustomSetting.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.customSetting.id = action.payload.id
      }
    })

    // getCommercialSetting
    builder
      .addCase(getCommercialSetting.pending, (state) => {
        state.commercialSettingInProcess = true
        state.commercialSetting = null
        state.commercialSettingMetaData = []
      })
      .addCase(getCommercialSetting.fulfilled, (state, action) => {
        state.commercialSettingInProcess = false
        state.commercialSetting = action!.payload!.data
        state.commercialSettingMetaData = orderBy(
          action!.payload!.metadata,
          ['ordinality'],
          ['asc']
        )
      })
      .addCase(getCommercialSetting.rejected, (state, action) => {
        state.commercialSettingInProcess = false
        state.commercialSetting = null
        state.commercialSettingMetaData = []
        state.errors = action.payload ?? null
      })

    // updateCommercialSetting
    builder.addCase(updateCommercialSetting.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.commercialSetting.id = action.payload.id
      }
    })

    // getPublisherSettings
    builder
      .addCase(getPublisherSettings.pending, (state) => {
        state.publisherSettingInProcess = true
        state.publisherSetting = null
        state.publisherSettingMetaData = []
      })
      .addCase(getPublisherSettings.fulfilled, (state, action) => {
        state.publisherSettingInProcess = false
        state.publisherSetting = action!.payload!.data
        state.publisherSettingMetaData = orderBy(action!.payload!.metadata, ['ordinality'], ['asc'])
      })
      .addCase(getPublisherSettings.rejected, (state, action) => {
        state.publisherSettingInProcess = false
        state.publisherSetting = null
        state.publisherSettingMetaData = []
        state.errors = action.payload ?? null
      })

    //  getPublisherFeatureSetting
    builder
      .addCase(getPublisherFeatureSetting.pending, (state) => {
        state.publisherFeatureSetting = null
        state.publisherFeatureSettingMetaData = []
        state.publisherFeatureSettingInProcess = true
      })
      .addCase(getPublisherFeatureSetting.fulfilled, (state, action) => {
        state.publisherFeatureSettingInProcess = false
        state.publisherFeatureSetting = action!.payload!.data
        state.publisherFeatureSettingMetaData = orderBy(
          action!.payload!.metadata,
          ['ordinality'],
          ['asc']
        )
      })
      .addCase(getPublisherFeatureSetting.rejected, (state, action) => {
        state.publisherFeatureSettingInProcess = false
        state.publisherFeatureSetting = null
        state.publisherFeatureSettingMetaData = []
        state.errors = action.payload ?? null
      })

    // updatePublisherFeatureSetting
    builder.addCase(updatePublisherFeatureSetting.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.publisherFeatureSetting.id = action.payload.id
      }
    })

    // updatePayoutSetting
    builder.addCase(updatePayoutSetting.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.payoutSetting.id = action.payload.id
      }
    })

    // updateLinkUserSetting
    builder.addCase(updateLinkUserSetting.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.linkUserSetting.id = action.payload.id
      }
    })

    //  getFewcentsCategories
    builder
      .addCase(getFewcentsCategories.pending, (state) => {
        state.fewcentsCategories = null
        state.fewcentsCategoriesInProcess = true
      })
      .addCase(getFewcentsCategories.fulfilled, (state, action) => {
        state.fewcentsCategoriesInProcess = false
        state.fewcentsCategories = action!.payload!.data
      })
      .addCase(getFewcentsCategories.rejected, (state, action) => {
        state.fewcentsCategoriesInProcess = false
        state.fewcentsCategories = null
        state.errors = action.payload ?? null
      })

    //getFewcentsCategoriesOfPublisher
    builder
      .addCase(getFewcentsCategoriesOfPublisher.pending, (state) => {
        state.fewcentsCategoriesOfPublisher = null
        state.fewcentsCategoriesOfPublisherInProcess = true
      })
      .addCase(getFewcentsCategoriesOfPublisher.fulfilled, (state, action) => {
        state.fewcentsCategoriesOfPublisherInProcess = false
        state.fewcentsCategoriesOfPublisher = action!.payload!.data
      })
      .addCase(getFewcentsCategoriesOfPublisher.rejected, (state, action) => {
        state.fewcentsCategoriesOfPublisherInProcess = false
        state.fewcentsCategoriesOfPublisher = null
        state.errors = action.payload ?? null
      })

    // updateFewcentsCategories
    builder.addCase(updateFewcentsCategories.fulfilled, (state, action) => {
      if (action.payload) {
        state.selectedFewcentsCategories = action.payload
      }
    })
  },
})
export const { setPublisherSettingData, setPublisherFeatureSettingData, setAdminError } =
  adminSlice.actions

export default adminSlice
