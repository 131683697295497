import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getAllPaymentMethods, getEmptyReward } from './ManagePaymentMethod-service'
import { PaymentMethodsType } from './ManagePaymentMethod-types'

const initialState: PaymentMethodsType = {
  list: [],
  getAllPaymentMethodsInProcessInProcess: false,
  errors: null,
  selectedPaymentMethod: getEmptyReward(),
}

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setSelectedPaymentMethod(state, action: PayloadAction<any>) {
      state.selectedPaymentMethod = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllPaymentMethods.pending, (state) => {
        state.getAllPaymentMethodsInProcessInProcess = true
      })
      .addCase(getAllPaymentMethods.fulfilled, (state, action) => {
        state.getAllPaymentMethodsInProcessInProcess = false
        state.list = action.payload
      })
      .addCase(getAllPaymentMethods.rejected, (state, action) => {
        state.getAllPaymentMethodsInProcessInProcess = false
        // state.errors = action?.payload
      })
  },
})

export const { setSelectedPaymentMethod } = paymentMethodsSlice.actions
export default paymentMethodsSlice
