import LeftNav from 'components/LeftNav'
import { makeStyles } from 'hooks/makeStyles'
import usePublisher from 'hooks/usePublisher'
import { FC, useEffect } from 'react'
import { useAppDispatch } from 'store/hooks'
import { getCountries } from 'store/slice/application-slice'
import { getPublisherCurrency } from 'store/slice/publisher-slice'
import './Layout.scss'

const useStyles = makeStyles()(() => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
}))

const Layout: FC = ({ children }) => {
  const { classes } = useStyles()
  const dispatch = useAppDispatch()
  const { selectedPublisher } = usePublisher()

  useEffect(() => {
    ;(async () => {
      await dispatch(getCountries())
    })()
  }, [dispatch])

  useEffect(() => {
    if (selectedPublisher) {
      dispatch(getPublisherCurrency({ publisherId: selectedPublisher.id }))
    }
  }, [selectedPublisher, dispatch])

  return (
    <div className={classes.root}>
      <LeftNav />
      {children}
    </div>
  )
}

export default Layout
