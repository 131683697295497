import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { TrafficResponse } from 'interfaces/response/trafficResponse.model'
import { ApiService } from './../../services/apiService'

interface PublisherHistorySliceInterface {
  trafficResponse: TrafficResponse
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: PublisherHistorySliceInterface = {
  trafficResponse: {
    totalCount: 0,
    items: [],
  },
  inProcess: false,
  errors: null,
}

const activitiesSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    setAllPublisherHistory(state, action: PayloadAction<TrafficResponse>) {
      state.trafficResponse = action.payload
    },

    setPublisherHistoryError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
    setPublisherHistoryProgress(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
  },
})

export const { setAllPublisherHistory, setPublisherHistoryError, setPublisherHistoryProgress } =
  activitiesSlice.actions

export const getPublisherHistory = createAsyncThunk(
  'activities/getPublisherHistory',
  async (arg: { publisherId: number; pageNumber: number; size: number }, { dispatch }) => {
    dispatch(setPublisherHistoryProgress(true))
    try {
      const response = await ApiService.get<TrafficResponse>(
        `/v1/publisher/${arg.publisherId}/history/${arg.pageNumber}/${arg.size}`
      )
      const { success, message, data } = response

      dispatch(setPublisherHistoryProgress(false))

      if (!success) {
        dispatch(setPublisherHistoryError({ error: message || COMMON_FAILURE }))
      } else {
        dispatch(setAllPublisherHistory(data))
      }
    } catch (ex: any) {
      dispatch(setPublisherHistoryProgress(true))
      dispatch(setPublisherHistoryError({ 'Server Error:': ex?.message || COMMON_FAILURE }))
    }
  }
)

export default activitiesSlice
