import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

interface UpdateArgs {
  inputData: any
}
interface CustomerRefundInterface {
  customerRefund: any | null
  customerRefundInProcess: boolean
  errors: { [k: string]: string } | null
}
const initialState: CustomerRefundInterface = {
  customerRefund: null,
  errors: null,
  customerRefundInProcess: false,
}
export const updateCustomerRefund = createAsyncThunk<any, UpdateArgs, RejectValue>(
  'customerRefund/updateCustomerRefund',
  async (args: UpdateArgs, { rejectWithValue }) => {
    const { inputData } = args
    const toasterId = toast.loading('Updating Customer refund details...')

    try {
      const response = await ApiService.post<any>(`/v1/publisher/admin/refundCustomer`, inputData)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

const customerRefundSlice = createSlice({
  name: 'customerRefund',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // updateCustomerRefund
    builder.addCase(updateCustomerRefund.fulfilled, (state, action) => {
      if (action.payload?.id) {
        state.customerRefund.id = action.payload.id
      }
    })
  },
})

export default customerRefundSlice
