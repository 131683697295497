import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getAllPublisher, setPublisherSelected } from 'store/slice/publisher-slice'

interface RouteParams {
  slug: string
}

let publishersLoaded = false
const usePublisher = () => {
  const slug = useParams<RouteParams>().slug
  const selectedPublisher = useAppSelector((store) => store.publisher.selectedPublisher)
  const publishers = useAppSelector((store) => store.publisher.publishers)
  const isPublishersInProcess = useAppSelector((store) => store.publisher.isPublishersInProcess)
  const user = useAppSelector((store) => store.user.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!user?.email) return
    if (!publishers.length && !isPublishersInProcess && !publishersLoaded) {
      publishersLoaded = true
      dispatch(getAllPublisher({ email: user.email }))
    } else if (publishers.length && !selectedPublisher) {
      dispatch(setPublisherSelected(slug || publishers[0].slug))
    }
  }, [user?.email, selectedPublisher, publishers, dispatch, isPublishersInProcess, slug])

  useEffect(() => {
    if (!publishers.length) return

    if (slug && selectedPublisher?.slug !== slug) {
      dispatch(setPublisherSelected(slug))
    }
  }, [dispatch, selectedPublisher, slug, publishers])

  const getDefaultMonetizeType = useCallback(() => {
    if (slug && selectedPublisher?.slug === slug) {
      if (selectedPublisher?.feature?.paywallEnabled && selectedPublisher?.feature?.tipjarEnabled) {
        return selectedPublisher?.primaryMonetizeType ?? 'paywall'
      } else if (selectedPublisher?.feature?.paywallEnabled) {
        return 'paywall'
      } else if (selectedPublisher?.feature?.tipjarEnabled) {
        return 'tipjar'
      } else {
        return 'inactive'
      }
    }
  }, [slug, selectedPublisher])

  return {
    getDefaultMonetizeType,
    selectedPublisher,
    publishers,
  }
}

export default usePublisher
