import { InternalRoute } from 'components/util'
import { Role } from 'interfaces/role.enum'
import { lazy } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useAppSelector } from 'store/hooks'

const Admin = lazy(() => import('containers/Admin'))
const Category = lazy(() => import('containers/Category'))
const Content = lazy(() => import('containers/Content'))
const Traffic = lazy(() => import('containers/Traffic'))
const Campaign = lazy(() => import('containers/Campaign'))
const Setup = lazy(() => import('containers/Setup'))
const Analytics = lazy(() => import('containers/Analytics'))
const Statistics = lazy(() => import('containers/Statistics'))
const Balance = lazy(() => import('containers/Balance'))
const Linkout = lazy(() => import('containers/Linkout'))
const LinkoutEditor = lazy(() => import('containers/Linkout/LinkoutEditor'))
const PageNotFound = lazy(() => import('components/PageNotFound'))
const BusinessRewardsDetails = lazy(() => import('containers/BusinessRewards/RewardsDetails'))
const PublisherOrdinality = lazy(() => import('components/PublisherOrdinality/index'))
const OnboardingScript = lazy(() => import('components/OnboardingScript/index'))
const ManageSponsorDetails = lazy(
  () => import('components/LeftNav/ManageSponsors/ManageSponsorDetails')
)
const PublisherRoute = () => {
  const { path } = useRouteMatch()
  const enableCampaign = useAppSelector((state) => state.globalData.enableCampaign)
  return (
    <Switch>
      <InternalRoute path={`${path}/category`}>
        <Category />
      </InternalRoute>
      <InternalRoute path={`${path}/content`}>
        <Content />
      </InternalRoute>
      <InternalRoute path={`${path}/setup`}>
        <Setup />
      </InternalRoute>
      <InternalRoute path={`${path}/traffic`}>
        <Traffic />
      </InternalRoute>
      <InternalRoute path={`${path}/analytics`}>
        <Analytics />
      </InternalRoute>
      <InternalRoute path={`${path}/statistics`}>
        <Statistics />
      </InternalRoute>
      <InternalRoute path={`${path}/linkout`}>
        <Linkout />
      </InternalRoute>
      <InternalRoute path={`${path}/linkout-editor`}>
        <LinkoutEditor />
      </InternalRoute>
      <InternalRoute path={`${path}/rewards-details`}>
        <BusinessRewardsDetails />
      </InternalRoute>
      <InternalRoute path={`${path}/sponsor-details`}>
        <ManageSponsorDetails />
      </InternalRoute>

      <InternalRoute path={`${path}/admin`}>
        <Admin />
      </InternalRoute>
      <InternalRoute path={`${path}/balance`}>
        <Balance />
      </InternalRoute>
      <InternalRoute path={`${path}/publisherOrdinality`}>
        <PublisherOrdinality />
      </InternalRoute>
      <InternalRoute path={`${path}/onboardingScript`}>
        <OnboardingScript />
      </InternalRoute>
      <Switch></Switch>
      {enableCampaign && (
        <InternalRoute path={`${path}/campaign`}>
          <Campaign />
        </InternalRoute>
      )}
      <Route path="*" component={PageNotFound} />
    </Switch>
  )
}

export default PublisherRoute
