import './RootLevelScreens.scss'

import React, { FC, memo } from 'react'

import Grid from '@mui/material/Grid'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

interface ComponentProps {
  name: string
  onClose: (open: boolean) => void
  icon: any
  tooltipTitle: string
  screenRoute: string
  leftSideBarFullMode: boolean
  canUpdate: boolean
}

const RootLevelScreens: FC<ComponentProps> = ({
  name,
  onClose,
  icon,
  tooltipTitle,
  screenRoute,
  leftSideBarFullMode,
}) => {
  return (
    <Grid className="RootLevelScreens_item--container">
      <Link onClick={() => onClose(false)} to={screenRoute} className="LeftNavUser__link">
        <Tooltip arrow title={tooltipTitle}>
          <span>{icon}</span>
        </Tooltip>
        {leftSideBarFullMode && <div className="screen-name">{name}</div>}
      </Link>
    </Grid>
  )
}

const MemoizedLeftNavUser = memo(RootLevelScreens)

export default MemoizedLeftNavUser
