import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getBusinessRewardsCampaigns,
  getEmptyReward,
  getFewcentsCategories,
  getPublisherCountries,
} from './businessRewards-service'
import { BusinessRewardType } from './businessRewards-types'

const initialState: BusinessRewardType = {
  list: [],
  publisherCountriesList: [],
  fewcentsCategoriesList: [],
  publishersDetails: [],
  getBusinessRewardsCampaignsInProcess: false,
  AddNewRewardCampaignDetailsInProcess: false,
  getPublisherCountriesInProcess: false,
  getFewcentsCategoriesInProcess: false,
  errors: null,
  selectedCampaign: getEmptyReward(),
  publisherDetailsForSelectedCampaign: false,
  publisherIdForSelectedTheRewardCampaign: null,
}

const businessRewardsSlice = createSlice({
  name: 'businessRewards',
  initialState,
  reducers: {
    setSelectedCampaign(state, action: PayloadAction<any>) {
      state.selectedCampaign = action.payload
    },
    setPublishersDetails(state, action: PayloadAction<any>) {
      state.publishersDetails = action.payload
    },
    setPublisherDetailsForSelectedCampaign(state, action: PayloadAction<any>) {
      state.publisherDetailsForSelectedCampaign = action.payload
    },
    setPublisherIdForSelectedTheRewardCampaignId(state, action: PayloadAction<any>) {
      state.publisherIdForSelectedTheRewardCampaign = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBusinessRewardsCampaigns.pending, (state) => {
        state.getBusinessRewardsCampaignsInProcess = true
      })
      .addCase(getBusinessRewardsCampaigns.fulfilled, (state, action) => {
        state.getBusinessRewardsCampaignsInProcess = false
        state.list = action.payload
      })
      .addCase(getBusinessRewardsCampaigns.rejected, (state, action) => {
        state.getBusinessRewardsCampaignsInProcess = false
        // state.errors = action?.payload
      })
      .addCase(getPublisherCountries.pending, (state) => {
        state.getPublisherCountriesInProcess = true
      })
      .addCase(getPublisherCountries.fulfilled, (state, action) => {
        state.getPublisherCountriesInProcess = false
        state.publisherCountriesList = action.payload
      })
      .addCase(getPublisherCountries.rejected, (state, action) => {
        state.getPublisherCountriesInProcess = false
        // state.errors = action?.payload
      })
      .addCase(getFewcentsCategories.pending, (state) => {
        state.getFewcentsCategoriesInProcess = true
      })
      .addCase(getFewcentsCategories.fulfilled, (state, action) => {
        state.getFewcentsCategoriesInProcess = false
        state.fewcentsCategoriesList = action.payload
      })
      .addCase(getFewcentsCategories.rejected, (state, action) => {
        state.getFewcentsCategoriesInProcess = false
        // state.errors = action?.payload
      })
  },
})

export const {
  setSelectedCampaign,
  setPublisherDetailsForSelectedCampaign,
  setPublisherIdForSelectedTheRewardCampaignId,
  setPublishersDetails,
} = businessRewardsSlice.actions
export default businessRewardsSlice
