import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { Country, CountryRegion } from 'interfaces/countryWithSubdivision.model'
import { GeoFencing, GeoFencingSettingRequest } from 'interfaces/geoFencing.model'
import { GeoPricingSettingRequest } from 'interfaces/geoPricing.model'
import { GeoPricingSetting } from 'interfaces/geoPricingSetting.model'
import {
  CountryWithRegionResponse,
  CountryWithSubdivisionResponse,
} from 'interfaces/response/countryWithSubdivisionResponse.model'
import toast from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { RootState } from 'store'
import { isNull, isNullOrEmptyOrUndefined } from 'util/helper'
import { setPublisherGeoFencingStatus, setPublisherGeoPricingStatus } from './publisher-slice'

type geographySliceType = {
  countries: CountryRegion[]
  countryInProgress: boolean
  selectedCountry: Country | null
  regionInProgress: boolean
  count: number
  inProcess: boolean
  errors: { [k: string]: string } | null
}

const initialState: geographySliceType = {
  countries: [],
  countryInProgress: false,
  selectedCountry: null,
  regionInProgress: true,
  count: 0,
  inProcess: false,
  errors: null,
}

const geographySlice = createSlice({
  name: 'geography',
  initialState,
  reducers: {
    setCountries(state, action: PayloadAction<CountryWithRegionResponse>) {
      const { count, countries } = action.payload
      state.countries = countries
      state.count = count
    },
    setCountriesGeoFencing(state, action: PayloadAction<{ row: CountryRegion; checked: boolean }>) {
      const { row, checked } = action.payload

      const helperCode = (row: { countryIsoCode: any; regionIsoCode: any }) => {
        if (row.countryIsoCode && isNull(row.regionIsoCode)) {
          const onlyCountry = state.countries.filter((x) => x.countryIsoCode === row.countryIsoCode)

          if (onlyCountry.length > 0) {
            onlyCountry.forEach((x) => {
              x.isGeoFencingEnabled = checked
            })
          }
        } else {
          const countryRegion = state.countries.find(
            (x) => x.countryIsoCode === row.countryIsoCode && x.regionIsoCode === row.regionIsoCode
          )

          if (countryRegion) {
            countryRegion.isGeoFencingEnabled = checked

            const onlyCountry = state.countries.find((x) => x.countryIsoCode === row.countryIsoCode)

            if (onlyCountry) {
              const isAnySelected = state.countries
                .filter((x) => x.countryIsoCode === row.countryIsoCode && !isNull(x.regionIsoCode))
                .some((x) => x.isGeoFencingEnabled)

              onlyCountry.isGeoFencingEnabled = isAnySelected
            }
          }
        }
      }
      Object.values(row).length > 0 &&
        Object.values(row).forEach((row) => {
          helperCode(row)
        })

      helperCode(row)
    },
    setCountriesGeoPricing(
      state,
      action: PayloadAction<{ row: CountryRegion; price: string | number }>
    ) {
      const { row, price } = action.payload

      const helperCode = (row: { countryIsoCode: any; regionIsoCode: any }) => {
        if (row.countryIsoCode && isNull(row.regionIsoCode)) {
          const onlyCountry = state.countries.filter((x) => x.countryIsoCode === row.countryIsoCode)
          if (onlyCountry.length > 0) {
            onlyCountry.forEach((x) => {
              x.geoPrice = price
            })
          }
        } else {
          const countryRegion = state.countries.find(
            (x) => x.countryIsoCode === row.countryIsoCode && x.regionIsoCode === row.regionIsoCode
          )

          if (countryRegion) {
            countryRegion.geoPrice = price
            countryRegion.isGeoPricingEntered = true

            const onlyCountry = state.countries.find((x) => x.countryIsoCode === row.countryIsoCode)
            if (onlyCountry) {
              onlyCountry.isGeoPricingEntered = true
            }
          }
        }
      }
      Object.values(row).length > 0 &&
        Object.values(row).forEach((row) => {
          helperCode(row)
        })

      helperCode(row)
    },
    setAllCountryGeoFencing(state, action: PayloadAction<boolean>) {
      if (state.countries.length > 0) {
        state.countries.forEach((x) => {
          x.isGeoFencingEnabled = action.payload
        })
      }
    },
    setAllCountryGeoPricing(state, action: PayloadAction<string>) {
      if (state.countries.length > 0) {
        state.countries.forEach((x) => {
          x.geoPrice = action.payload
        })
      }
    },
    setInProcess(state, action: PayloadAction<boolean>) {
      state.inProcess = action.payload
    },
    setSelectedCountry(state, action: PayloadAction<Country>) {
      state.selectedCountry = action.payload
      state.regionInProgress = false
    },
    clearSelectedCountry(state, action: PayloadAction) {
      state.selectedCountry = null
    },
    resetSelectedCountry(state, action: PayloadAction) {
      state.selectedCountry = null
    },
    setGeographyError(state, action: PayloadAction<{}>) {
      state.errors = action.payload
    },
  },
  extraReducers: (builder) => {
    // getCountryWithGeographySection
    builder
      .addCase(getCountryWithGeographySection.pending, (state) => {
        state.countries = []
        state.countryInProgress = true
      })
      .addCase(getCountryWithGeographySection.fulfilled, (state, action) => {
        state.countryInProgress = false
        state.countries = action.payload?.countries
        state.count = action.payload?.count!
      })
      .addCase(getCountryWithGeographySection.rejected, (state, action) => {
        state.countries = []
        state.countryInProgress = false
        state.errors = action.payload ?? null
      })
  },
})

export const {
  setCountries,
  setCountriesGeoFencing,
  setCountriesGeoPricing,
  setInProcess,
  setSelectedCountry,
  setGeographyError,
  resetSelectedCountry,
  clearSelectedCountry,
  setAllCountryGeoFencing,
  setAllCountryGeoPricing,
} = geographySlice.actions

export const getCountryWithGeographySection = createAsyncThunk<
  CountryWithRegionResponse,
  {
    publisherId: number
    monetizeType: string
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'geography/getCountryWithGeographySection',
  async (arg: { publisherId: number; monetizeType: string }, { rejectWithValue }) => {
    try {
      const getCountries = ApiService.get<CountryWithSubdivisionResponse>(
        `/v1/geo/countryWithSubdivisions`
      )
      const getPublisherGeoFencingSettings = ApiService.get<GeoFencing[]>(
        `/v1/publisher/${arg.publisherId}/geoFencingSettings`
      )
      const getPublisherGeoPricingSettings = ApiService.get<GeoPricingSetting[]>(
        `/v1/publisher/${arg.publisherId}/geoPricingSettings`
      )

      const [countryResponse, publisherGeoFencingResponse, getPublisherGeoPricingSettingsResponse] =
        await Promise.all([
          getCountries,
          getPublisherGeoFencingSettings,
          getPublisherGeoPricingSettings,
        ])

      if (
        !countryResponse.success ||
        !publisherGeoFencingResponse.success ||
        !getPublisherGeoPricingSettingsResponse.success
      ) {
        return rejectWithValue({ 'Server Error:': 'Can not fetch Geography Data' })
      } else {
        const countries = countryResponse.data.countries
        const publisherGeoFencing = publisherGeoFencingResponse.data
        const publisherGeoPricing = getPublisherGeoPricingSettingsResponse.data

        const countryData: CountryRegion[] = []

        countries.forEach((country) => {
          countryData.push({
            countryName: country.name,
            countryIsoCode: country.isoCode,
            regionName: '',
            regionIsoCode: null,
            geoPrice: '',
            isGeoFencingEnabled: false,
            isGeoPricingEntered: false,
          })

          if (country.subDivisionOne) {
            country.subDivisionOne.forEach((region) => {
              countryData.push({
                countryName: country.name,
                countryIsoCode: country.isoCode,
                regionName: region.name,
                regionIsoCode: region.isoCode,
                geoPrice: '',
                isGeoFencingEnabled: false,
                isGeoPricingEntered: false,
              })
            })
          }
        })

        publisherGeoFencing
          .filter((x) => x.monetizeType === arg.monetizeType)
          .forEach((geoFencing) => {
            const country = countryData.filter((x) => x.countryIsoCode === geoFencing.countryCode)
            if (country.length > 0) {
              const countryWithoutRegion = country.find((x) => x.countryIsoCode && !x.regionIsoCode)
              const countryWithRegion = country.filter((x) => x.countryIsoCode && x.regionIsoCode)

              if (countryWithoutRegion && geoFencing.countryCode && !geoFencing.regionCode) {
                countryWithoutRegion.isGeoFencingEnabled = true
              } else if (
                countryWithRegion.length > 0 &&
                geoFencing.regionCode &&
                geoFencing.countryCode
              ) {
                countryWithRegion.forEach((region) => {
                  if (region.regionIsoCode === geoFencing.regionCode) {
                    region.isGeoFencingEnabled = true
                  }
                })
              }
            }
          })

        publisherGeoPricing
          .filter((x) => x.monetizeType === arg.monetizeType)
          .forEach((geoPricing) => {
            const country = countryData.filter((x) => x.countryIsoCode === geoPricing.countryCode)

            if (country.length > 0) {
              const countryWithoutRegion = country.find((x) => x.countryIsoCode && !x.regionIsoCode)
              const countryWithRegion = country.filter((x) => x.countryIsoCode && x.regionIsoCode)

              if (countryWithoutRegion && geoPricing.countryCode && !geoPricing.regionCode) {
                countryWithoutRegion.geoPrice = geoPricing.priceFactor
              } else if (
                countryWithRegion.length > 0 &&
                geoPricing.regionCode &&
                geoPricing.countryCode
              ) {
                countryWithRegion.forEach((region) => {
                  if (region.regionIsoCode === geoPricing.regionCode) {
                    region.geoPrice = geoPricing.priceFactor
                  }
                })
              }
            }
          })

        return {
          count: countryData.length,
          countries: countryData,
        } as CountryWithRegionResponse
      }
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const updateGeoFencingCheck = createAsyncThunk<
  { publisherId: number; geoFencingEnabled: boolean },
  {
    publisherId: number
    geoFencingEnabled: boolean
  },
  {
    rejectValue: { [k: string]: string }
  }
>(
  'publisher/updateGeoFencingCheck',
  async (
    args: { publisherId: number; geoFencingEnabled: boolean },
    { dispatch, rejectWithValue }
  ) => {
    const { publisherId, geoFencingEnabled } = args

    const enablingText = geoFencingEnabled ? 'enabling' : 'disabling'
    const enableText = geoFencingEnabled ? 'enable' : 'disable'

    if (geoFencingEnabled) {
      dispatch(
        setPublisherGeoFencingStatus({ publisherId: +publisherId, enabled: geoFencingEnabled })
      )
      return args
    }

    const toasterId = toast.loading(`Geo-Fencing is ${enablingText}...`)

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/enableDisableGeoFencing/${geoFencingEnabled}`,
        {}
      )

      const { success, message } = response

      if (!success) {
        toast.error(message || COMMON_FAILURE, { id: toasterId })
        return rejectWithValue({ 'Server Error:': message || COMMON_FAILURE })
      } else {
        dispatch(
          setPublisherGeoFencingStatus({ publisherId: +publisherId, enabled: geoFencingEnabled })
        )

        toast.success(`Geo-Fencing ${enableText} successfully.`, { id: toasterId })
        return args
      }
    } catch (ex: any) {
      toast.error(`Failed to ${enablingText} Geo-Pricing.`, { id: toasterId })
      return rejectWithValue({ 'Server Error:': ex?.message || COMMON_FAILURE })
    }
  }
)

export const updateGeoPricingCheck = createAsyncThunk<
  any,
  {
    publisherId: number
    geoPricingEnabled: boolean
  }
>(
  'publisher/updateGeoPricingCheck',
  async (args: { publisherId: number; geoPricingEnabled: boolean }, { dispatch }) => {
    const { publisherId, geoPricingEnabled } = args

    const enablingText = geoPricingEnabled ? 'enabling' : 'disabling'
    const enableText = geoPricingEnabled ? 'enable' : 'disable'

    if (geoPricingEnabled) {
      dispatch(
        setPublisherGeoPricingStatus({ publisherId: publisherId, enabled: geoPricingEnabled })
      )

      return args
    }

    const toasterId = toast.loading(`Geo-Pricing is ${enablingText}...`)

    try {
      const response = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/enableDisableGeoPricing/${geoPricingEnabled}`,
        {}
      )

      const { success, message, data } = response
      if (!success) {
        toast.error(message || COMMON_FAILURE, { id: toasterId })
        return null
      } else {
        dispatch(
          setPublisherGeoPricingStatus({ publisherId: publisherId, enabled: geoPricingEnabled })
        )

        toast.success(`Geo-Pricing ${enableText} successfully.`, { id: toasterId })
        return data
      }
    } catch (ex: any) {
      toast.error(`Failed to ${enableText} Geo-Pricing.`, { id: toasterId })
      return null
    }
  }
)

export const saveGeoFencingData = createAsyncThunk<
  boolean,
  {
    publisherId: number
    monetizeType: string
    geoFencingEnabled: boolean
  },
  { state: RootState }
>(
  'geography/saveGeoFencingData',
  async (
    args: {
      publisherId: number
      monetizeType: string
      geoFencingEnabled: boolean
    },
    { dispatch, getState }
  ) => {
    const toasterId = toast.loading('Saving Geo-Fencing...')

    try {
      const {
        geography: { countries },
      } = getState()

      const { monetizeType, publisherId, geoFencingEnabled } = args

      const filterCountries = countries.filter((x) => {
        return x.isGeoFencingEnabled
      })

      const geoFencingSettingRequest: GeoFencingSettingRequest = {
        geoFencingSetting: {
          geoFencingEnabled: geoFencingEnabled,
          monetizeType: monetizeType,
          geoFencingSets: [],
        },
      }

      const countryList = filterCountries.filter((x) => x.countryIsoCode && isNull(x.regionIsoCode))

      const regionList = filterCountries.filter((x) => x.countryIsoCode && !isNull(x.regionIsoCode))

      countryList.forEach((country) => {
        geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
          country: country.countryIsoCode!,
          region: null,
        })

        const countryRegion = regionList.filter((x) => x.countryIsoCode === country.countryIsoCode)

        if (countryRegion.length > 0) {
          countryRegion.forEach((region) => {
            if (region.isGeoFencingEnabled) {
              geoFencingSettingRequest.geoFencingSetting.geoFencingSets.push({
                country: country.countryIsoCode!,
                region: region.regionIsoCode,
              })
            }
          })
        }
      })

      const updateGeoFencingSettingResponse = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/updateGeoFencingSettings`,
        geoFencingSettingRequest
      )

      if (!updateGeoFencingSettingResponse.success) {
        const errorMessage = updateGeoFencingSettingResponse.message

        dispatch(setGeographyError({ 'Server Error:': errorMessage }))

        toast.error(errorMessage, { id: toasterId })

        return false
      } else {
        toast.success('Geo-Fencing saved.', { id: toasterId })

        return true
      }
    } catch (ex: any) {
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))

      toast.error('Failed to save Geo-Fencing.', { id: toasterId })

      return false
    }
  }
)

export const saveGeoPricingData = createAsyncThunk<
  boolean,
  {
    publisherId: number
    monetizeType: string
    geoPricingEnabled: boolean
  },
  { state: RootState }
>(
  'geography/saveGeoPricingData',
  async (
    args: {
      publisherId: number
      monetizeType: string
      geoPricingEnabled: boolean
    },
    { dispatch, getState }
  ) => {
    const toasterId = toast.loading('Saving Geo-Pricing...')

    try {
      const {
        geography: { countries },
      } = getState()

      const { monetizeType, publisherId, geoPricingEnabled } = args

      const filterCountries = countries.filter((x) => x.isGeoPricingEntered || x.geoPrice)

      const geoPricingSettingRequest: GeoPricingSettingRequest = {
        geoPricingSetting: {
          geoPricingEnabled: geoPricingEnabled,
          geoPricingSets: [],
          monetizeType: monetizeType,
        },
        publisherId: publisherId,
      }

      const countryList = filterCountries.filter((x) => x.countryIsoCode && isNull(x.regionIsoCode))

      const regionList = filterCountries.filter((x) => x.countryIsoCode && !isNull(x.regionIsoCode))

      countryList.forEach((country) => {
        if (!isNullOrEmptyOrUndefined(country.geoPrice)) {
          geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
            country: country.countryIsoCode!,
            region: '',
            priceFactor: country.geoPrice!,
          })
        }

        const countryRegions = regionList.filter((x) => x.countryIsoCode === country.countryIsoCode)

        if (countryRegions.length > 0) {
          countryRegions.forEach((region) => {
            if (!isNullOrEmptyOrUndefined(region.geoPrice)) {
              geoPricingSettingRequest.geoPricingSetting.geoPricingSets.push({
                country: country.countryIsoCode!,
                region: region.regionIsoCode!,
                priceFactor: region.geoPrice!,
              })
            }
          })
        }
      })

      const updateGeoPricingSettingResponse = await ApiService.put<any>(
        `/v1/publisher/${publisherId}/updateGeoPricingSettings`,
        geoPricingSettingRequest
      )
      if (!updateGeoPricingSettingResponse.success) {
        const errorMessage = updateGeoPricingSettingResponse.message

        dispatch(setGeographyError({ 'Server Error:': errorMessage }))

        toast.error(errorMessage, { id: toasterId })

        return false
      } else {
        toast.success('Geo-Pricing saved.', { id: toasterId })

        return true
      }
    } catch (ex: any) {
      dispatch(setGeographyError({ 'Server Error:': ex?.message || COMMON_FAILURE }))

      toast.error('Failed to save Geo-Pricing.', { id: toasterId })

      return false
    }
  }
)

export default geographySlice
