import * as React from 'react'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  marginLeft: '12px',
  color: 'white',
  border: `none`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'white' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#08289A',
  color: 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
    marginLeft: '2px',
  },
  '& .MuiAccordionSummary-content': {
    margin: `2px 0 2px ${theme.spacing(1)}`,
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: '0 0 2px 20px',
  border: 'none',
  backgroundColor: '#08289A',
  color: 'white',
}))

const Menu = () => {
  const [expanded, setExpanded] = React.useState<string | false>('')
  const { t } = useTranslation()

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false)
  }

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          sx={{ minHeight: '30px', paddingLeft: '20px' }}
        >
          <Typography sx={{ fontSize: '12px !important' }}>
            <Link to={`/resourceCenter/Getting+Started`} className="resource-link">
              {t('resourceMenu.gettingStarted')}
            </Link>
          </Typography>
        </AccordionSummary>
        {/* <AccordionDetails>
          <List sx={{ padding: 0 }}>
            <ListItem>
              <Link to={`/resourceCenter/Getting+Started+with+Linkout`} className="resource-link">
                {t('resourceMenu.withLinkout')}
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/resourceCenter/Getting+Started+with+Tipjar`} className="resource-link">
                {t('resourceMenu.withTipjar')}
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/resourceCenter/Getting+Started+with+Paywall`} className="resource-link">
                {t('resourceMenu.withPaywall')}
              </Link>
            </ListItem>
          </List>
        </AccordionDetails> */}
      </Accordion>
      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          aria-controls="panel2d-content"
          id="panel2d-header"
          sx={{ minHeight: '30px', paddingLeft: '20px' }}
        >
          <Typography sx={{ fontSize: '12px !important' }}>
            <Link to={`/resourceCenter/Integrations`} className="resource-link">
              {t('resourceMenu.integrations')}
            </Link>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List>
            <ListItem>
              <Link to={`/resourceCenter/Setting+up+Linkout`} className="resource-link">
                {t('resourceMenu.settingUpLinkout')}
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/resourceCenter/Fewcents+Integration+guide`} className="resource-link">
                {t('resourceMenu.fewcentsIntegrationGuide')}
              </Link>
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion> */}
    </div>
  )
}

export default Menu
