import { Dashboard } from '@mui/icons-material'

const screenKeyStore = {
  admin: {
    customizeUI: 'Admin:CustomizeUI',
    commercials: 'Admin:Commercials',
    setting: 'Admin:Setting',
    keys: 'Admin:Keys',
    publisherFeature: 'Admin:PublisherFeature',
    payout: 'Admin:Payout',
    linkUser: 'Admin:LinkUser',
    fewcentsCategories: 'Admin:FewcentsCategories',
    termAndPolicyUser:'Admin:TermsAndPolicy'
  },
  dashboard: {
    acceptBid: 'Dashboard:AcceptBid',
    customerRefund: 'Dashboard:CustomerRefund',
    addPublisher: 'Dashboard:AddPublisher',
    activatePublisher: 'Dashboard:ActivatePublisher',
    businessRewards: 'Dashboard:BusinessRewards',
    manageSponsor: 'Dashboard:ManageSponsor',
    managePaymentMethod: 'Dashboard:ManagePaymentMethod',
  },
  linkout: {
    approve: 'Linkout:Approve',
  },
}

export default screenKeyStore
