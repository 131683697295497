import { createAsyncThunk } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import { isNullOrEmptyOrUndefined } from 'util/helper'
import {
  ApproveArgs,
  ChangeLinkoutArgs,
  GetLinkoutArgs,
  RejectValue,
  SaveArgs,
  SlugResponse,
  UpdateArgs,
} from './linkout-types'
import { LinkoutAsset } from './linkout-types'

export const linkOutOwners = {
  Creator: 'creator',
  Publisher: 'publisher',
}

export function getEmptyAsset(linkOutOwner?: string): LinkoutAsset {
  const owner = linkOutOwner || linkOutOwners.Creator
  return {
    linkOutOwner: owner,
    name: '',
    id: -1,
    monetizeType: owner === linkOutOwners.Creator ? 'tipjar' : 'paywall',
    code: '',
    description: owner === linkOutOwners.Creator ? '<h2>About me<h2>' : '<h2>Description<h2>',
    active: true,
    createdTime: '',
    actionButtonText: 'Tip',
    summary: '',
    walletUrl: '',
    publisherAccessKey: '',
    checkoutURL: '',
    assetGraphicUrl: '',
    tipjarPriceOptions: { prices: ['0', '0', '0'] },
    priceOptions: { prices: ['0'] },
    creatorProfilePictureUrl: '',
    creatorContactInfoText: 'For business inquiries, reach me at:',
    creatorContactInfoEmail: '',
    creatorInstagramLink: '',
    creatorTwitterLink: '',
    creatorFacebookLink: '',
    creatorTiktokLink: '',
    creatorYoutubeLink: '',
    unlockPrice: 0,
    utmSource: '',
    utmCampaign: '',
    utmMedium: '',
    paymentCompletedUrl: '',
    paymentErrorUrl: '',
    assetDynamicContent: '',
    category: '',
    pageFontFamily: '',
    pageBorderColor: '#dcdcdc',
    pageTextColor: '#000000',
    pageBackgroundColor: '#ffffff',
    pluginColor: '#000000',
    websiteUrl: '',
    externalButtonsHtml: '',
    externalButtons: { backgroundColor: '#000000', list: [] },
    checkoutContentType: 'normal',
    mediaSourceUrl: '',
    approved: false,
  }
}

export const getLinkoutAsset = createAsyncThunk<LinkoutAsset[], GetLinkoutArgs, RejectValue>(
  'linkout/get',
  async (args: GetLinkoutArgs, { rejectWithValue }) => {
    const toasterId = 'getLinkoutToaster'

    try {
      const url = `/v1/publisher/${args.publisherId}/digitalAsset`
      const response = await ApiService.get<LinkoutAsset[]>(url)
      const { data, success, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      return data.map((x) => {
        x.externalButtons = x.externalButtonsHtml
          ? JSON.parse(x.externalButtonsHtml)
          : { backgroundColor: '#ffffff', list: [] }
        return x
      })
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const saveLinkoutAsset = createAsyncThunk<any, SaveArgs, RejectValue>(
  'linkout/save',
  async (args: SaveArgs, { rejectWithValue }) => {
    const { publisherId, linkoutAsset } = args
    const toasterId = toast.loading('Saving Linkout...')

    try {
      const url = `/v1/publisher/${publisherId}/digitalAsset`
      const { id, tipjarPriceOptions, externalButtons, priceOptions, monetizeType, ...payload } =
        linkoutAsset

      const payload1: any = {
        ...payload,
        monetizeType,
        externalButtonsHtml: externalButtons.list?.length ? JSON.stringify(externalButtons) : '',
        priceOptions: monetizeType === 'tipjar' ? tipjarPriceOptions : priceOptions,
      }
      const response = await ApiService.post<{ checkoutServiceUrl: string }>(url, payload1)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const updateLinkoutAsset = createAsyncThunk<any, UpdateArgs, RejectValue>(
  'linkout/update',
  async (args: UpdateArgs, { rejectWithValue }) => {
    const { publisherId, assetId, linkoutAsset } = args

    const {
      monetizeType,
      priceOptions,
      unlockPrice,
      tipjarPriceOptions,
      externalButtons,

      ...payload
    } = linkoutAsset
    const payload1: any = {
      ...payload,
      monetizeType,
      externalButtonsHtml: JSON.stringify(externalButtons),
      priceOptions:
        monetizeType === 'tipjar'
          ? tipjarPriceOptions
          : priceOptions
          ? priceOptions
          : { prices: [unlockPrice] },
    }
    const toasterId = toast.loading('Updating Linkout...')

    try {
      const url = `/v1/publisher/${publisherId}/digitalAsset/${assetId}`
      const response = await ApiService.put<{ checkoutServiceUrl: string }>(url, payload1)
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const approveLinkout = createAsyncThunk<any, ApproveArgs, RejectValue>(
  'linkout/approve',
  async (args: ApproveArgs, { rejectWithValue }) => {
    const { publisherId, assetId } = args
    const toasterId = toast.loading(`Approving the linkout`)

    try {
      const url = `/v1/publisher/${publisherId}/digitalAsset/${assetId}/approve/true`
      const response = await ApiService.put<LinkoutAsset>(url, {})
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const changeLinkoutActiveState = createAsyncThunk<any, ChangeLinkoutArgs, RejectValue>(
  'linkout/active',
  async (args: ChangeLinkoutArgs, { rejectWithValue }) => {
    const { publisherId, assetId, active } = args
    const activeStateText = active ? 'Active' : 'Inactive'
    const toasterId = toast.loading(`Changing linkout state to ${activeStateText}...`)

    try {
      const url = `/v1/publisher/${publisherId}/digitalAsset/${assetId}/${active}`
      const response = await ApiService.put<LinkoutAsset>(url, {})
      const { success, data, message } = response

      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }

      toast.success(message, { id: toasterId })
      return data
    } catch (ex: any) {
      const errorMessage = ex?.message || COMMON_FAILURE
      toast.error(errorMessage, { id: toasterId })
      return rejectWithValue({ 'Server Error:': errorMessage })
    }
  }
)

export const validateSlug = async (publisherId: number, slug: string) => {
  const toasterId = 'validateSlug'
  try {
    const url = `/v1/publisher/${publisherId}/validateSlug`
    const { success, data, message } = await ApiService.post<SlugResponse>(url, { slug })
    return { success, data, message }
  } catch (ex: any) {
    const errorMessage = ex?.message || COMMON_FAILURE
    toast.error(errorMessage, { id: toasterId })
  }
}
