import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { COMMON_FAILURE } from 'constants/common'
import { toast } from 'react-hot-toast'
import { ApiService } from 'services/apiService'
import axios from 'axios'
import config from 'config'

interface RejectMessage {
  [k: string]: string
}
interface RejectValue {
  rejectValue: RejectMessage
}

interface UpdateArgs {
  title: string
}
interface ResourcePages {
  pages: any | null
  errors: { [k: string]: string } | null
  inProcess: any
  isError: boolean
}
const initialState: ResourcePages = {
  pages: null,
  errors: null,
  inProcess: null,
  isError: false
}

export const getResources = createAsyncThunk<any, any, RejectValue>(
  'resource/getResource',
  async (args, { rejectWithValue }) => {
    const toasterId = 'Resource pages'
    let title = args.title as string 
    try {
      const response = await ApiService.get(`/v1/documentation/${title}/confluence`)   
      const { success, message, data } = response
     
      if (!success) {
        const errorMessage = message || COMMON_FAILURE
        toast.error(errorMessage, { id: toasterId })
        return rejectWithValue({ 'Server Error:': errorMessage })
      }
      return data
    } catch (ex: any) {
      toast.error(COMMON_FAILURE, { id: toasterId })
      return rejectWithValue({ 'Server Error:': COMMON_FAILURE })
    }
  }
)

const getRsourceSlice = createSlice({
  name: 'resource',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getResources.pending, (state) => {
      state.inProcess = true
    })
    builder.addCase(getResources.fulfilled, (state, action) => {
      state.inProcess = false
      state.pages = JSON.parse(action.payload)
    })
    builder.addCase(getResources.rejected, (state, action) => {
      state.inProcess = false
      state.isError = true
      state.pages = action.payload
    })
  },
})

export default getRsourceSlice
