import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserAuth } from 'interfaces/userAuth.model'

const initialState: UserAuth = {
  email: null,
  password: null,
  privacyPolicyCheck: true,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    editEmailPassword(state, action: PayloadAction<UserAuth>) {
      const userAuthData = action.payload

      state.email = userAuthData.email
      state.password = userAuthData.password
    },
    editPrivacyPolicyCheck(state, action: PayloadAction<boolean>) {
      state.privacyPolicyCheck = action.payload
    },
  },
})

export const { editEmailPassword, editPrivacyPolicyCheck } = authSlice.actions

export default authSlice
