import { useEffect } from 'react'
import decodeToken from 'util/decodeToken'
import { getNextToken } from 'components/Login/LoginBox/getNextToken'
import { getAuthToken } from 'util/auth'
import { JWTDecodeModel } from 'interfaces/jwtDecode.model'

let tokenTimeout: any

const useTokenExpiryCheck = () => {
  const token = getAuthToken()
  let decoded: JWTDecodeModel | undefined | null = null

  if (token) {
    decoded = decodeToken(token)
  }

  useEffect(() => {
    if (decoded) {
      const expires = new Date(decoded.exp * 1000).getTime()
      // const timeout = expires - Date.now() - 59 * 60 * 1000 - 45 * 1000;
      const timeout = expires - Date.now()

      if (timeout > 0) tokenTimeout = setTimeout(getNextToken, timeout * 0.95)
    }

    return () => clearTimeout(tokenTimeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return decoded
}

export default useTokenExpiryCheck
