import { createTheme } from '@mui/material'

const theme = createTheme({
  palette: {
    primary: {
      main: '#08289A',
    },
    text: {
      disabled: 'rgba(0, 0, 0, 0.80)',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 540,
      md: 720,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiDialogContent: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: `${theme.spacing(2)} !important`,
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#6e7191 !important',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginRight: 0,
          marginLeft: 0,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: ({ ownerState }) => ({
          ...(ownerState.shrink && {
            fontSize: '0.9rem !important',
          }),
        }),
      },
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif !important',
    body1: {
      fontSize: '0.875rem !important',
    },
    h4: {
      fontSize: '1.7rem !important',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.5rem !important',
    },
    h6: {
      fontSize: '1.1rem !important',
    },
  },
})

export default theme
