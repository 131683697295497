import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getSponsorBusinessManage, getEmptyReward } from './sponsorBusinessManage-service'
import { SponsorBusinessManageType } from './sponsorBusinessManage-types'

const initialState: SponsorBusinessManageType = {
  list: [],
  getSponsorBusinessManageInProcess: false,

  errors: null,
  selectedSponsor: getEmptyReward(),
}

const sponsorBusinessManageSlice = createSlice({
  name: 'manageSponsor',
  initialState,
  reducers: {
    setSelectedSponsor(state, action: PayloadAction<any>) {
      state.selectedSponsor = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSponsorBusinessManage.pending, (state) => {
        state.getSponsorBusinessManageInProcess = true
      })
      .addCase(getSponsorBusinessManage.fulfilled, (state, action) => {
        state.getSponsorBusinessManageInProcess = false
        state.list = action.payload
      })
      .addCase(getSponsorBusinessManage.rejected, (state, action) => {
        state.getSponsorBusinessManageInProcess = false
        // state.errors = action?.payload
      })
  },
})

export const { setSelectedSponsor } = sponsorBusinessManageSlice.actions
export default sponsorBusinessManageSlice
