import { Box } from '@mui/material'

const Loading = () => {
  return <div>Loading...</div>
}

const LoadingCenter = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      Loading...
    </Box>
  )
}

export { LoadingCenter }
export default Loading
